import setDefaultOptions from 'date-fns/setDefaultOptions';

import i18n from 'translations/i18n';
import { dateFnsLocaleMapper } from 'utils/dateUtil';

export const localization = {
  data: {
    // @ts-ignore
    key: dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en',
    //@ts-ignore
    locale: dateFnsLocaleMapper[dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'] as Locale
  },
  get() {
    return this.data;
  }
}

export const globalLocale = {
  // @ts-ignore
  key: dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en',
  //@ts-ignore
  locale: dateFnsLocaleMapper[dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'] as Locale
}

export const updateLocale = (localeKey: string, reload = true, forceReload = false) => {
  if(!forceReload && globalLocale.key === localeKey) return;
  i18n.changeLanguage(localeKey)
  //@ts-ignore
  globalLocale.key = dateFnsLocaleMapper[localeKey] ? i18n.resolvedLanguage : 'en';
  //@ts-ignore
  globalLocale.locale = dateFnsLocaleMapper[dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'];

  // @ts-ignore
  setDefaultOptions({ locale: globalLocale.locale });

  if(reload) {
    // Spike for moment when will be solution how to work with dictionaries
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  }
};

setDefaultOptions({ locale: globalLocale.locale });

//@ts-ignore
window.updateLocale = updateLocale;

