import React, { ElementType, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ButtonBase as MUIButton } from '@mui/material';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import Tooltip from 'components/Tooltip/Tooltip';

import { IconsMapType } from 'assets/icons/icons';

import styles from './Button.module.scss';

type BUTTON_VARIANT = 'primary' | 'secondary' | 'text' | 'toolbar';
type BUTTON_ELEMENT = 'button' | 'a' | 'link';

export type ButtonProps<V extends BUTTON_ELEMENT = 'button'> = {
  component?: V;
  isLoading?: boolean;
  disabled?: boolean;
  // {null} needed to turn off icon for prefilled button wrappers (AddButton, DeleteButton, etc.)
  startIcon?: keyof IconsMapType | null;
  startIconClassName?: string;
  // {null} needed to turn off icon for prefilled button wrappers (AddButton, DeleteButton, etc.)
  endIcon?: keyof IconsMapType | null;
  endIconClassName?: string;
  variant?: BUTTON_VARIANT;
  label?: string;
  className?: string;
  children?: ReactNode;
  isActive?: boolean;
  name?: string;
  dataId?: string;
  defaultFormWidth?: boolean;
  tooltipTitle?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  size?: 'small' | 'default';
} & (V extends 'button'
  ? {
      onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
      href?: never;
      to?: never;
    }
  : V extends 'a'
  ? {
      onClick?: never;
      href?: string;
      target?: string;
      to?: never;
    }
  : V extends 'link'
  ? {
      component: 'link';
      onClick?: never;
      to: string;
      target?: string;
    }
  : {});

function Button<V extends BUTTON_ELEMENT>({
  label,
  component = 'button' as V,
  className,
  variant = 'primary',
  startIcon,
  startIconClassName,
  endIcon,
  endIconClassName,
  disabled,
  isLoading,
  children,
  isActive,
  dataId,
  defaultFormWidth,
  tooltipTitle,
  fullWidth,
  size,
  ...props
}: ButtonProps<V>) {
  const componentElement = component === 'link' ? Link : component;
  const showTooltip = tooltipTitle && disabled && !isLoading;
  const showTooltipText = showTooltip && tooltipTitle;

  return (
    <Tooltip content={showTooltipText}>
      <MUIButton
        component={componentElement as ElementType}
        className={cn(
          'Button_component',
          styles.component,
          variant && styles[variant],
          isLoading && styles.loading,
          disabled && styles.disabled,
          isActive && styles.isActive,
          defaultFormWidth && styles.defaultFormWidth,
          showTooltipText && styles.showTooltipText,
          fullWidth && styles.fullWidth,
          size && styles[size],
          className,
        )}
        disabled={disabled || isLoading}
        data-id={dataId}
        {...props}
        // style={disabled ? { pointerEvents: 'none' } : {}}
      >
        {startIcon && <Icon name={startIcon} className={cn(styles.startIcon, startIconClassName)} />}
        {(label || children) && <span className={styles.label}>{label || children}</span>}
        {endIcon && <Icon name={endIcon} className={cn(styles.endIcon, endIconClassName)} />}
        {isLoading && <Spinner />}
      </MUIButton>
    </Tooltip>
  );
}

export default Button;
