import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import * as yup from 'yup';

import { REACT_APP_GOOGLE_OAUTH } from 'appInfo';
import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import InputPassword from 'components/controls/InputPassword/InputPassword';
import Icon from 'components/Icon/Icon';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import { AuthMode } from 'components/pages/Auth/LoginPage/LoginPage';
import SocialAuth from 'components/pages/Auth/SocialAuth/SocialAuth';
import useYupForm, { useValidationSchema } from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';

import styles from './LoginForm.module.scss';

const INITIAL_FORM_DATA = {
  password: '',
  email: '',
}

export interface LoginFormProps {
  isInviteMode: boolean;
  setMode: (mode: AuthMode) => void;
}

const LoginForm: FC<LoginFormProps> = ({ isInviteMode, setMode }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const location = useLocation();
  const urlQuery = queryString.parse(location.search);
  const { onLogin } = useAuth();
  const yupCustomScheme = useValidationSchema()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required(),
        password: yupCustomScheme.password.required(),
      })),
    defaultValues: {...INITIAL_FORM_DATA, email: urlQuery.e || ''},
  });

  const onSubmit = (data: any) => {
    onLogin(data);
  };

  return (
    <div className={styles.component}>
      <div>
        <Box className={styles.box}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                {...register('email')}
                label="Email"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                className={styles.textField}
                disabled={isInviteMode}
              />
            </div>
            <div>
              <InputPassword
                label={i18nt('PASSWORD')}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
                className={styles.textField}
                type="password"
                registerProps={register('password')}
                showPassword={showPassword}
                triggerShowPassword={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className={styles.buttons}>
              <Button type="submit" variant="primary">
                {i18nt('LOGIN')}
              </Button>
              <Button variant="text" onClick={() => setMode('registration')}>
                {i18nt('SIGN_UP')}
              </Button>
            </div>
          </form>
        </Box>
        <div className={styles.restoreAccessButtonBlock}>
          <button className={styles.restoreAccessButton} onClick={() => setMode('restoreAccess')}>
            {i18nt('RESTORE_ACCESS')}
          </button>
        </div>
        <SocialAuth key="social_login_page"/>
      </div>
    </div>
  );
};

export default LoginForm;
