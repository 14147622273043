import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';

import {getApiCodeError} from 'api/api';
import { apiRestoreAccess } from 'api/auth';
import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import showNotification from 'components/Notifications/showNotification';
import { AuthMode } from 'components/pages/Auth/LoginPage/LoginPage';
import useYupForm, { useValidationSchema } from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';

import styles from './RestoreForm.module.scss';

const INITIAL_FORM_DATA = {
  email: '',
}

export type RestoreFormProps =  {
  setMode: (mode: AuthMode) => void;
}

const RestoreForm = ({setMode}: RestoreFormProps) => {
  const yupCustomScheme = useValidationSchema()
  const [confirmationView, setConfirmationView] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required()
      })),
    defaultValues: {...INITIAL_FORM_DATA}
  });

  const onSubmit = (data: any) => {
    apiRestoreAccess({...data})
      .then(() => {
        setConfirmationView(true)
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          content: getApiCodeError(error),
        });
      })
  };

  return (
    <div>
      <Box className={styles.box}>
        {!confirmationView && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                {...register('email')}
                label="Email"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                className={styles.textField}
              />
            </div>
            <div className={styles.buttons}>
              <Button type="submit" variant="primary">
                {i18nt('RESTORE_ACCESS')}
              </Button>
            </div>
          </form>
        )}
        {confirmationView && (
          <div className={styles.confirmationBox}>
            <div className={styles.confirmationIcon}>
              <MarkEmailReadOutlinedIcon/>
            </div>
            {i18nt('PLEASE_CHECK_YOUR_MAIL')}
          </div>
        )}
      </Box>
      <div className={styles.buttonsBlock}>
        <button className={styles.restoreAccessButton} onClick={() => setMode('login')}>
          {i18nt('LOGIN')}
        </button>
      </div>
    </div>
  );
};

export default RestoreForm;
