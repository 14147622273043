import React, { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import cn from 'classnames';

import Routing from 'app/Routing';
import { REACT_APP_GOOGLE_OAUTH } from 'appInfo';
import DeviceProvider, { mobileCheck } from 'providers/DeviceProvider';
import GoogleAnalyticsProvider from 'providers/GoogleAnalyticsProvider';
import LocalStorageProvider from 'providers/LocalStorageProvider';
import i18n from 'translations/i18n';

import Notifications from '../components/Notifications/Notifications';
import AuthProvider from '../components/pages/Auth/AuthProvider';

import styles from './App.module.scss';

import 'utils/localization';

export const App: FC = () => {
  useEffect(() => {
    //@ts-ignore
    window.scrollTo(0, 5);
    //
    // document.documentElement.requestFullscreen();
  }, []);

  return (
    <div className={cn(styles.component, mobileCheck() && 'isMobile')}>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_OAUTH || ''}>
        <StyledEngineProvider injectFirst>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              {/*<StoreProvider>*/}
              <DeviceProvider>
                <LocalStorageProvider>
                  <AuthProvider>
                    {/*<GoogleAnalyticsProvider>*/}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/*@ts-ignore*/}
                      <Routing />
                    </LocalizationProvider>
                    {/*</GoogleAnalyticsProvider>*/}
                  </AuthProvider>
                  <Notifications />
                  {/*</StoreProvider>*/}
                </LocalStorageProvider>
              </DeviceProvider>
            </BrowserRouter>
          </I18nextProvider>
        </StyledEngineProvider>
      </GoogleOAuthProvider>
    </div>
  );
};
