import React, { useEffect, useState } from 'react';

import {
  CredentialResponse,
  GoogleLogin,
  useGoogleOneTapLogin,
  // useGoogleLogin,
  // PromptMomentNotification
} from '@react-oauth/google';
import cn from 'classnames';

import {apiAuthByGoogle} from 'api/auth';
// import {SocialAuthCallback} from 'components/AuthForm/SocialAuthPage/types';

import { REACT_APP_GOOGLE_OAUTH } from 'appInfo';
import Icon from 'components/Icon/Icon';
import showNotification from 'components/Notifications/showNotification';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import { SocialAuthCallbackArgs } from 'components/pages/Auth/SocialAuth/types';
import { i18nt } from 'translations/i18n';
import cookies from 'utils/cookies';

import styles from './SocialAuth.module.scss';

type Props = {
  className?: string;
  showOrBlock?: boolean;
  hiddenButtonByDefault?: boolean;
  showOnlyButtonWithoutOneTap?: boolean;
};

const SocialAuth = ({ className, showOrBlock = true, hiddenButtonByDefault = true, showOnlyButtonWithoutOneTap }: Props) => {
  const { setUser } = useAuth();
  const [hideButton, setHideButton] = useState(hiddenButtonByDefault);

  if (!REACT_APP_GOOGLE_OAUTH) return null;

  const callback = ({user, error}: SocialAuthCallbackArgs) => {
    if(user) {
      setUser(user, Object.keys(user.companies).length === 0 ? '/addCompany' : undefined);

      showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')});
      // window.location.href = `${urlRedirectUrl}?init_token=${user.apiToken}`;
    }
    if(error) {
      const code = error?.data.errors?.[0]?.code;
      //@ts-ignore
      const errorTextByCode = VALIDATION_ERRORS?.[code] as string;

      if(errorTextByCode) {
        showNotification({type: 'error',content: errorTextByCode});

        return;
      }
      showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
    }
  }

  const errorCallback= (error?: any) => {
    console.log('error');
    callback({error});
  }
  const handleSuccessGoogle= (response: CredentialResponse) => {
    apiAuthByGoogle(response)
      .then((response) => {
        callback({user: response.data});
      })
      .catch((e) => {
        callback({error: e});
      })
  }

  useEffect(() => {
    cookies.delete('g_state');
    // login();
  }, []);

  if(!showOnlyButtonWithoutOneTap) {
    useGoogleOneTapLogin({
      onError: errorCallback,
      onSuccess: handleSuccessGoogle,
      promptMomentNotification: (data: any) => {
        console.log('promptMomentNotification', data);
        // debugger;
        //@ts-ignore
        if(data.h === false || data.g === 'skipped') {
          // setIsLoading(false);
          setHideButton(false);
        }
      },
    })
  }

  if(hideButton) return null;

  return (
    <div className={cn(styles.component, className)}>
      {showOrBlock && (
        <div className={styles.orSocialTitle}>
          <span />
          <div className={styles.orSocialText}>or</div>
          <span />
        </div>
      )}
      {/*<button className={styles.button} onClick={() => {}}>*/}
      {/*  <Icon name="googleColor" className={styles.icon} />*/}
      {/*  Sign in with Google*/}
      {/*</button>*/}
      <GoogleLogin
        onSuccess={handleSuccessGoogle}
        // onError={errorCallback}
        onError={() => {
          console.log('@@@@')
        }}
      />
    </div>
  );
};

export default SocialAuth;
