import React, { FC } from 'react';
import PhoneInputComponent, { PhoneInputProps } from 'react-phone-input-2'

import cn from 'classnames';

import { globalLocale } from 'utils/localization';

import 'react-phone-input-2/lib/style.css'

import styles from './PhoneInput.module.scss';

export type InputProps = PhoneInputProps & {
  className?: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
}

const EXCLUDED_COUNTRIES = ['ru', 'by'];

const PhoneInput: FC<InputProps> = ({ className, error, helperText, country, readOnly,...props }) => {

  const isError = error && helperText;

  const defaultCountryKey = () => {
    if(globalLocale?.key === 'en') return 'ua';

    if(globalLocale?.key) return globalLocale?.key;

    if(country) return country;

    return 'ua'
  }

  return (
    <div className={cn(styles.component, { [styles.error]: isError }, className)}>
      <PhoneInputComponent country={defaultCountryKey()} excludeCountries={EXCLUDED_COUNTRIES} disabled={readOnly} {...props}/>
      {isError && (
        <div className={styles.errorText}>
          {helperText}
        </div>
      )}
    </div>
  )
}

export default PhoneInput;
