import React, { FC } from 'react';

import Dialog from '@mui/material/Dialog';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';

import styles from './Modal.module.scss';

export interface ModalProps {
  className?: string;
  paperClassName?: string;
  contentClassName?: string;
  open?: boolean;
  onClose?: () => void;
  title?: string;
  mobileFullScreen?: boolean;
}

const Modal: FC<ModalProps> = ({ open = false, onClose, paperClassName, title,className, contentClassName, mobileFullScreen, children }) =>
// const {isMobile} = useDevice();

// const mobileProps = isMobile && mobileFullScreen ? {
//   fullScreen: mobileFullScreen
// }: {};

// looks like if we need fullscreen modal better to use <Panel> component

  (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: cn(styles.paper, mobileFullScreen && styles.mobileFullScreenPaper, paperClassName) }}
      // fullScreen={mobileFullScreen}
      //{...mobileProps}
    >
      <div className={className}>
        {(title || onClose) && (
          <div className={cn(styles.header, !title && styles.justCancel)}>
            <div className={styles.title}>
              {title}
            </div>
            <Icon name="cancel" className={styles.closeIcon} onClick={onClose} />
          </div>

        )}
        <div className={cn(styles.content, contentClassName)}>
          {children}
        </div>

      </div>
    </Dialog>
  )

export default Modal;
