import React from 'react';

import cn from 'classnames';

import styles from './MapEmbed.module.scss';

interface Props {
  className?: string;
  mapAddress?: string;
  allowFullScreen?: boolean;
  placeCard?: boolean
}

const KK = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapEmbed = ({ mapAddress, className, allowFullScreen, placeCard = false }: Props) => (
  <div className={cn(styles.component, className)}>
    <iframe
      loading="lazy"
      //@ts-ignore
      allowfullscreen={allowFullScreen}
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${KK}&q=${mapAddress}`}
    />
  </div>
)

export default MapEmbed;
