import { AutocompleteOptionType } from 'components/Selects/types';

import countryCurrencies from './countryCurrencies.json';

export type countryCurrenciesItemType = {
  countryName: string,
  currencyName: string,
  currency: string
}
export type countryCurrenciesType = {
  [key: string]: countryCurrenciesItemType
}

export type CurrenciesOptionsExtendedType = countryCurrenciesItemType & AutocompleteOptionType;

export type CurrenciesOptionsType = AutocompleteOptionType<{},string>;

const _currencyOptions = Object.values(countryCurrencies).reduce((acc: CurrenciesOptionsType[], country: countryCurrenciesItemType) => {
  if(acc.find(item => item.value === country.currency)) return acc;

  return [...acc, {
    value: country.currency,
    label: `${country.currency}`,
  }]
}, [])

export const getSortedCurrencies = (order: string[]) => {
  const _order = [...order].reverse();

  return _currencyOptions.reduce((acc: CurrenciesOptionsType[], item) => {
    if(_order.includes(<string>item.value)) {
      return [item, ...acc];
    }

    return [...acc, item];
  }, []);
}

const currenciesOrder = ['UAH', 'USD', 'EUR'];

const timezonesToCurrencies = {
  'Europe/Andorra': 'EUR',
  'Europe/Tirane': 'ALL',
  'Europe/Vienna': 'EUR',
  'Europe/Brussels': 'EUR',
  'Europe/Sarajevo': 'BAM',
  'Europe/Sofia': 'BGN',
  'Europe/Zagreb': 'EUR',
  'Europe/Prague': 'CZK',
  'Europe/Copenhagen': 'DKK',
  'Europe/Tallinn': 'EUR',
  'Europe/Helsinki': 'EUR',
  'Europe/Paris': 'EUR',
  'Europe/Berlin': 'EUR',
  'Europe/Gibraltar': 'GIP',
  'Europe/Athens': 'EUR',
  'Europe/Budapest': 'HUF',
  'Atlantic/Reykjavik': 'ISK',
  'Europe/Rome': 'EUR',
  'Europe/Riga': 'EUR',
  'Europe/Vilnius': 'EUR',
  'Europe/Luxembourg': 'EUR',
  'Europe/Skopje': 'MKD',
  'Europe/Malta': 'EUR',
  'Europe/Monaco': 'EUR',
  'Europe/Amsterdam': 'EUR',
  'Europe/Oslo': 'NOK',
  'Europe/Warsaw': 'PLN',
  'Europe/Lisbon': 'EUR',
  'Europe/Bucharest': 'RON',
  'Europe/Belgrade': 'RSD',
  'Europe/Bratislava': 'EUR',
  'Europe/Ljubljana': 'EUR',
  'Europe/Madrid': 'EUR',
  'Europe/Stockholm': 'SEK',
  'Europe/Zurich': 'CHF',
  'Europe/Istanbul': 'TRY',
  'Europe/Kiev': 'UAH',
  'Europe/London': 'GBP',
  'America/New_York': 'USD',
  'America/Los_Angeles': 'USD',
  'America/Chicago': 'USD',
  'America/Denver': 'USD',
  'America/Phoenix': 'USD',
  'America/Anchorage': 'USD',
  'America/Adak': 'USD',
  'America/Honolulu': 'USD'
}

export const getCurrenciesList = (timezone: string) => {
  //@ts-ignore
  const currentTimeZoneCurrency = timezonesToCurrencies?.[timezone] || 'UAH';

  return getSortedCurrencies([...new Set([currentTimeZoneCurrency, currenciesOrder])]);
}

export const CURRENCY_OPTIONS = getSortedCurrencies(currenciesOrder)
