import React, { FC } from 'react';

import cn from 'classnames';

import icons, { IconsMapType } from 'assets/icons/icons';

import styles from './Icon.module.scss';

export interface IconProps {
  name: keyof IconsMapType;
  className?: string
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
}

const Icon: FC<IconProps> = ({ name, className, onClick,...props }) => {
  const IconComponent = icons[name];

  if(!IconComponent) return null;

  return <IconComponent className={cn('Icon', styles.component, className)} onClick={onClick} {...props}/>;
};

export default Icon;
