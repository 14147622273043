import React, { useState } from 'react';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { v4 as uuid } from 'uuid';

import styles from './MapMarker.module.scss';

interface MapMarkerProps {
  onChange?: (value: string) => void;
  data?: string;
}

type MarkerData = {
  id: string,
  position: {
    lat: number,
    lng: number,
  }
}

const center = {
  lat: 46.0922994,
  lng: 17.2078588
};

const MapMarker = ({onChange, data}: MapMarkerProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  })

  const dataPosition: MarkerData | undefined = data ? {
    id: uuid(),
    position: {
      lat: Number(data.split(',')[0]),
      lng: Number(data.split(',')[1]),
    },
  }: undefined;

  const [marker, setMarker] = useState<MarkerData | undefined>(dataPosition);

  const onMapClick = (event: any) => {
    const latLng = event.latLng;

    setMarker({
      id: uuid(),
      position: {
        lat: latLng.lat(),
        lng: latLng.lng(),
      },
    });
    onChange?.(`${latLng.lat()},${latLng.lng()}`);
  };

  // const [map, setMap] = React.useState(null)
  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //
  //   map.fitBounds(bounds);
  //
  //   setMap(map)
  // }, [])
  //
  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  if(!isLoaded) return null;

  return (
    //@ts-ignore because of the lib limitation
    <GoogleMap
      mapContainerClassName={styles.mapContainer}
      center={center}
      // zoom={3.3}
      zoom={3.5}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
      onClick={onMapClick}
    >
      {marker && (
        //@ts-ignore because of the lib limitation
        <Marker
          key={marker.id}
          position={marker?.position}
          // icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
          // onMarkerClick={onMarkerClick}
        />
      )}
    </GoogleMap>
  )
};

export default MapMarker;
