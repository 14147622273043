import React from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import { getLanguageByKey } from 'translations/utils';
import { updateLocale } from 'utils/localization';

import styles from './AuthLanguageFooter.module.scss';

interface AuthLanguageFooterProps {
  className?: string;
}

const AuthLanguageFooter = ({ className }: AuthLanguageFooterProps) => {
  const {i18n} = useTranslation();

  const handleClick = (language: string) => () => {
    updateLocale(language)
  }
  const languages = Object.keys(i18n.store.data)

  return (
    <div className={cn(styles.component, className)}>
      {languages.map(languageKey => (
        <div className={cn(styles.item, i18n.resolvedLanguage === languageKey && styles.selected)} onClick={handleClick(languageKey)} key={`language-${languageKey}`}>
          {getLanguageByKey(languageKey)}
        </div>
      ))}
    </div>
  )

}

export default AuthLanguageFooter;
