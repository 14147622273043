import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import * as yup from 'yup';

import {getApiCodeError} from 'api/api';
import { apiRestoreAccessConfirmation } from 'api/auth';
import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import showNotification from 'components/Notifications/showNotification';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import useYupForm, {useValidationSchema} from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';

import styles from './RestoreConfirmationForm.module.scss';

const INITIAL_FORM_DATA = {
  password: '',
  passwordConfirmation: '',
  email: '',
}

const RestoreConfirmationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlQuery = queryString.parse(location.search);
  const { setUser } = useAuth();
  const yupCustomScheme = useValidationSchema()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required(),
        password: yupCustomScheme.passwordRegistration,
        passwordConfirmation: yupCustomScheme.passwordConfirmation,
      })),
    defaultValues: {...INITIAL_FORM_DATA, email: urlQuery.e}
  });

  const onSubmit = (data: any) => {
    apiRestoreAccessConfirmation({...data, token: urlQuery.init_token})
      .then(result => {
        setUser(result.data)
        navigate('/');
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          content: getApiCodeError(error),
        });
      })
  };

  return (
    <Box className={styles.box}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            {...register('email')}
            label="Email"
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            className={styles.textField}
            disabled
          />
        </div>
        <div>
          <TextField
            {...register('password')}
            label={i18nt('PASSWORD')}
            error={!!errors?.password?.message}
            helperText={errors?.password?.message}
            className={styles.textField}
            type="password"
          />
        </div>
        <div>
          <TextField
            {...register('passwordConfirmation')}
            label={i18nt('PASSWORD_CONFIRMATION')}
            error={!!errors?.passwordConfirmation?.message}
            helperText={errors?.passwordConfirmation?.message}
            className={styles.textField}
            type="password"
          />
        </div>
        <div className={styles.buttons}>
          <Button type="submit" variant="primary">
            {i18nt('CHANGE_PASSWORD')}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default RestoreConfirmationForm;
