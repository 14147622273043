import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { getApiCodeError } from 'api/api';
import { ApiConfig } from 'api/apiTypes';
import { apiCreateCompanyWithOrganization, apiDeleteCompany, apiGetCompanies } from 'api/company';
import useApi from 'api/useApi';
import showNotification from 'components/Notifications/showNotification';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
import { UserRoleType } from 'components/types';
import { i18nt } from 'translations/i18n';
import { CompanyDTO } from 'types/company';

type CompaniesContextType = {
  company: CompanyDTO | null;
  companyId: number;
  companies: CompanyDTO[] | null;
  setCompany: (company: CompanyDTO | null) => void;
  deleteCompany: (companyId: number) => void;
  createCompanyWithOrganization: (data: any, ) => Promise<any>;
  companyUerRoles: UserRoleType[];
  isLoading: boolean;
  loadCompanies: () => void;
};

const CompaniesContextInitialState = {
  company: null,
  companyId: 0,
  companies: [],
  setCompany: _.noop,
  companyUerRoles: [],
  isLoading: false,
  loadCompanies: _.noop,
  deleteCompany: _.noop,
  createCompanyWithOrganization: () => new Promise((resolve) => resolve(true)),
};

const CompaniesContext = React.createContext<CompaniesContextType>(
  CompaniesContextInitialState
);

const CompaniesProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const {user, loadUserData} = useAuth()
  const [company, setCompany] = useState<CompanyDTO | null>(null);
  const [companyUerRoles, setCompanyUerRoles] = useState<UserRoleType[]>([]);
  const { data: companies, isLoading, forceRequest: loadCompanies } = useApi<CompanyDTO[]>(
    apiGetCompanies
  );
  const [companyCreating, setCompanyCreating] = useState(false);
  const getCompanyFromUrl = () => {
    const locationArray = location.pathname.split('/');
    const organizationIndex = locationArray.indexOf('company');
    const orgId = locationArray?.[`${organizationIndex + 1}`];

    return orgId ? Number(orgId) : orgId;
  };

  const updateCompanyIdInUrl = (companyId: number) => {
    const locationArray = location.pathname.split('/');
    const companyIndex = locationArray.indexOf('company');

    if(companyIndex !== -1) {
      locationArray[`${companyIndex + 1}`] = `${companyId}`;
      const newCompanyUrl = `${locationArray.join('/')}`;

      navigate(newCompanyUrl);

      return;
    }
  }

  const handleCompany = useCallback((company: CompaniesContextType['company']) => {
    setCompany(company);

    const companyId = company?.id

    if(companyId) {
      updateCompanyIdInUrl(companyId);
    }
  }, [setCompany])

  const handleDeleteCompany = useCallback((companyId: number) => {
    apiDeleteCompany({ companyId })
      .then(() => {
        const isLastCompany = companies!.length === 1;

        if(!isLastCompany) {
          setCompany(companies!.filter((company) => company.id !== companyId)[0]);
          navigate(`/company/${companies?.[0]?.id}`);
        } else {
          window.location.href = window.location.origin;

          return;
        }

        loadCompanies();
        showNotification({ type: 'success', content: i18nt('DELETED_SUCCESSFULLY') });
      })
      .catch(() => {
        showNotification({ type: 'error', content: i18nt('ERROR_CONTACT_ADMIN') });
      })
  }, [companies]);

  const createCompanyWithOrganization = useCallback( ({data, isDefaultNavigation = true, config}: {data: any, isDefaultNavigation?: boolean, config?: ApiConfig}) => {
    setCompanyCreating(true);

    const preparedData = {
      ...data,
      organization: {
        ...data.organization || {},
        gallery: data.gallery ? JSON.stringify(data.gallery) : JSON.stringify([]),
      }
    }

    return apiCreateCompanyWithOrganization(preparedData, {showRequestError:false})
      .then((result) => {
        loadCompanies();
        setCompany(result.data as unknown as CompanyDTO);
        //https://barbergil.atlassian.net/browse/BAR-395
        // After creating a company, the menu on the left is not updated automatically
        loadUserData();

        if(config?.showRequestError !== false) {
          showNotification({
            type: 'success',
            content: i18nt('NEW_COMPANY_CREATED_SUCCESSFULLY'),
          });
        }

        isDefaultNavigation && navigate('/');
      })
      .catch((e) => {
        showNotification({
          type: 'error',
          content: getApiCodeError(e) || i18nt('COMPANY_NOT_CREATED'),
        });
        setCompanyCreating(false);
        throw 'error'
      });
  }, []);

  useEffect(() => {
    if(companies && !company) {
      const companyId = getCompanyFromUrl();
      const newCompany = companies?.find(comp => comp.id === companyId) || companies[0]

      setCompany(newCompany);
    }

  }, [companies, company]);

  useEffect(() => {
    if (!company || !user) return;
    const userOrganizations = user.companies;
    const companyRoles = userOrganizations?.[company.id]?.roles;
    const isAdmin = user!.roles.find((role) => role.name === 'admin');

    if(isAdmin) {
      setCompanyUerRoles([{id: 0, name: 'admin'},...(companyRoles || [])] || [])
    } else {
      setCompanyUerRoles(companyRoles || [])
    }
    companyCreating && setCompanyCreating(false);
  }, [company, user])

  const value = useMemo(
    () => ({
      company:  company as CompanyDTO,
      companyId: company?.id as CompanyDTO['id'],
      setCompany: handleCompany,
      deleteCompany: handleDeleteCompany,
      companies,
      companyUerRoles,
      isLoading: isLoading && !company?.id && !companyCreating,
      loadCompanies,
      createCompanyWithOrganization,
    }),
    [
      company,
      setCompany,
      companies,
      companyUerRoles,
      isLoading,
      loadCompanies,
      handleDeleteCompany,
      createCompanyWithOrganization,
    ]
  );

  return (
    <CompaniesContext.Provider value={value}>
      {isLoading && !company?.id && !companyCreating ? (
        <SpinnerPage className="CompaniesProvider_provider" />
      ) : (
        children
      )}
    </CompaniesContext.Provider>
  );
};

export const useCompanies = () => useContext(CompaniesContext);
// const [token, setToken] = React.useState(null);
export default CompaniesProvider;
