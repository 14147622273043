import React, { FC } from 'react';

import cn from 'classnames';

import styles from './ModalFooter.module.scss';

interface Props {
  className?: string;
  align?: 'left' | 'center' | 'right';
}

const ModalFooter: FC<Props> = ({ className, align, children }) => (
  <div className={cn(styles.component, align === 'right' && styles.alignRight, align === 'center' && styles.alignCenter, className)}>
    {children}
  </div>
);

export default ModalFooter;
