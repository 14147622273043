import i18n from 'i18next';
import * as yup from 'yup';

import {i18nt} from 'translations/i18n';
import { ServiceSubItemDTO } from 'components/types';

export const useValidationSchemaConstructor = () => ({

  // employee
  employeeName: yup.string().min(2, i18nt('PLEASE_FILL_FIELDS')).required(),
  employeeRolesIds: yup.array().min(1, i18nt('VALIDATION.REQUIRED_FIELD')).required(),

  //general
  languages: yup.array().of(yup.string()),
  email: yup.string().email(i18nt('VALIDATION.EMAIL_INVALID')).min(1, i18nt('VALIDATION.REQUIRED_FIELD')),
  //@ts-ignore
  emailCanBeEmpty: yup.string().test('is-email-or-empty', 'Email is invalid', (value: string | undefined) =>
  // Allow empty string or valid email
    value === '' || yup.string().email(i18nt('VALIDATION.EMAIL_INVALID')).min(1, i18nt('VALIDATION.REQUIRED_FIELD')).isValidSync(value)
  ),
  servicesIds: yup.array().of(yup.string()),
  rolesIds: yup.array().of(yup.number()),

  // password: yup.string().min(6, i18nt('VALIDATION.PASSWORD_MIN_LENGTH')).required(i18nt('VALIDATION.REQUIRED_FIELD')),
  password: yup.string(),
  passwordRegistration: yup.string().min(8, i18nt('VALIDATION.PASSWORD_MIN_LENGTH')).required(),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], i18nt('VALIDATION.PASSWORDS_NOT_MATCH')),
  userName: yup.string(),
  servicesEmployee: yup.array().of(yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
    price: yup.number().required(),
    image: yup.string().nullable(),
  })),
  services: yup.array().of(yup.object({
    id: yup.string().required(),
    name: yup.string().required(),
    price: yup.number().required(),
    parentId: yup.number().required(),
    image: yup.string().nullable(),
    items: yup.array().of(yup.object({
      id: yup.string().required(),
      name: yup.string().required(),
      price: yup.number().required(),
      parentId: yup.number().required(),
      image: yup.string().nullable(),
    })).nullable(),
    type: yup.string().oneOf(['category', 'item']),
  })),
})

const initGlobalYupLocaleConfig = () => {
  yup.setLocale({
    mixed: {
      required: i18nt('VALIDATION.REQUIRED_FIELD'),
    },
  });
}

i18n.on('languageChanged', (language) => {
  initGlobalYupLocaleConfig();
});

initGlobalYupLocaleConfig();
