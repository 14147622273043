import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import * as yup from 'yup';

import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import AuthWrapper from 'components/pages/Auth/AuthWrapper/AuthWrapper';
import useYupForm, { useValidationSchema } from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';

import { useAuth } from '../AuthProvider';

import styles from 'components/pages/Auth/RegistrationPage/RegistrationPage.module.scss';

type RegistrationPageProps = {
  invite?: boolean;
}

const INITIAL_FORM_DATA = {
  password: '',
  passwordConfirmation: '',
  email: '',
  name: ''
}
const RegistrationPage: FC<RegistrationPageProps> = ({invite: inviteProp}) => {
  const location = useLocation();
  const yupCustomScheme = useValidationSchema()

  const urlQuery = queryString.parse(location.search);

  const isInviteMode = Boolean(inviteProp && urlQuery.e);
  const { onRegistration } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required(),
        name: yup.string().required(),
        password: yupCustomScheme.passwordRegistration,
        passwordConfirmation: yupCustomScheme.passwordConfirmation,
      })),
    defaultValues: isInviteMode ? {...INITIAL_FORM_DATA, email: urlQuery.e} : INITIAL_FORM_DATA
  });

  const onSubmit = (data: any) => {
    onRegistration(data);
  };

  return (
    <AuthWrapper className={styles.component}>
      <Box className={styles.box}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              {...register('email')}
              label="Email"
              error={!!errors?.email?.message}
              helperText={errors?.email?.message}
              className={styles.textField}
              disabled={isInviteMode}
            />
          </div>
          <div>
            <TextField
              {...register('name')}
              label="Name"
              error={!!errors?.name?.message}
              helperText={errors?.name?.message}
              className={styles.textField}
            />
          </div>
          <div>
            <TextField
              {...register('password')}
              label={i18nt('PASSWORD')}
              error={!!errors?.password?.message}
              helperText={errors?.password?.message}
              className={styles.textField}
              type="password"
            />
          </div>
          <div>
            <TextField
              {...register('passwordConfirmation')}
              label={i18nt('PASSWORD_CONFIRMATION')}
              error={!!errors?.passwordConfirmation?.message}
              helperText={errors?.passwordConfirmation?.message}
              className={styles.textField}
              type="password"
            />
          </div>
          <div className={styles.buttons}>
            <Button type="submit" variant="primary">
              {isInviteMode ? 'Увійти' : 'Зареєструватися'}
            </Button>
          </div>
        </form>
      </Box>
    </AuthWrapper>
  );
};

export default RegistrationPage;
