import React, { ChangeEvent, DetailedHTMLProps, FC, useRef, useState } from 'react';

import cn from 'classnames';

import ButtonAdd from 'components/Buttons/examples/ButtonAdd';
import ButtonDelete from 'components/Buttons/examples/ButtonDelete/ButtonDelete';
import ButtonEdit from 'components/Buttons/examples/ButtonEdit';
import Icon from 'components/Icon/Icon';
import useFileUpload from 'hooks/useFileUpload';
import { i18nt } from 'translations/i18n';

import styles from 'components/FileUpload/FileUpload.module.scss';

export type FileUploadProps = {
  className?: string;
  classNameImageWrapper?: string;
  readOnly?: boolean;
  value?: string;
  addLabel?: string;
  deleteLabel?: string;
  editLabel?: string;
  showDelete?: boolean;
  defaultSize?: boolean;
  closeIcon?: boolean;
  entity?: 'image';
  inputProps?: DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
} & (
  | {
      externalFileUpload?: false;
      onChange: (value: string, e: ChangeEvent<HTMLTextAreaElement>) => void;
    }
  | {
      externalFileUpload: true;
      onChange: (value: string, e: ChangeEvent<HTMLTextAreaElement>, file?: File) => void;
    }
);

const FileUpload: FC<FileUploadProps> = ({
  value,
  onChange,
  className,
  classNameImageWrapper,
  readOnly,
  addLabel = i18nt('ADD'),
  deleteLabel = i18nt('DELETE'),
  editLabel = i18nt('EDIT'),
  externalFileUpload,
  showDelete = false,
  defaultSize = true,
  closeIcon= true,
  entity,
  inputProps,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const entityName = entity ? i18nt('IMAGE'): null

  const { uploadFile } = useFileUpload();

  const changeHandler = (event: any) => {
    const file: File = event.target.files[0];

    if (externalFileUpload) {
      onChange(URL.createObjectURL(file), event, file);

      return;
    }
    uploadFile(file as File).then(imageURL => onChange(imageURL, event));
    // onChange(URL.createObjectURL(file), event);
  };

  const onClear = () => {
    onChange('', { target: { value: '' } } as ChangeEvent<HTMLTextAreaElement>)
  }

  return (
    <div className={cn('FileUpload', styles.component, defaultSize && styles.defaultSize, readOnly && styles.readOnly, className)}>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        ref={ref}
        className={styles.nativeInput}
        accept="image/*"
        {...inputProps}
      />
      {value && (
        <div className={cn(styles.imageWrapper, classNameImageWrapper)}>
          <img src={value} alt="" />
        </div>
      )}

      {!readOnly && (
        <>
          {!value && (
            <div className={cn(styles.buttons)}>
              <ButtonAdd onClick={() => ref?.current?.click()}>{addLabel} {entityName}</ButtonAdd>
            </div>
          )}
          {value && (
            <div className={cn(styles.buttons, styles.buttonsEditMode)}>
              <ButtonEdit onClick={() => ref?.current?.click()}>{editLabel} {entityName}</ButtonEdit>
              {showDelete && (
                <ButtonDelete
                  variant="text"
                  onClick={onClear}
                >
                  {deleteLabel} {entityName}
                </ButtonDelete>
              )}
            </div>
          )}
        </>
      )}
      {!readOnly && value && closeIcon && (
        <div className={styles.closeIconControl}>
          <Icon name="cancel" onClick={onClear} className={styles.closeIcon}/>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
