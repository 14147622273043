function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export const getLanguageByKey = (languageKey: string) => {
  const languageLocalKey = languageKey.toLowerCase() === 'ua' ? 'UK' : languageKey;
  //@ts-ignore
  const lang = new Intl.DisplayNames([languageLocalKey], {type: 'language'})

  const languageName = lang.of(languageLocalKey);

  return languageName ? capitalize(languageName): languageName as string;
}
