import React, { FC, ReactNode, ReactText } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

import HeaderContent from 'components/LayoutContent/HeaderContent/HeaderContent';
import NoData from 'components/NoData/NoData';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
import { useDevice } from 'providers/DeviceProvider';

import Spinner from '../Spinner/Spinner';

import styles from './LayoutContent.module.scss';

export const LAYOUT_TOOLBAR_ID = 'layout-toolbar';
export const LAYOUT_TOOLBAR_ID_MOBILE = 'layout-toolbar-mobile';

export interface ILayoutContent {
  title: ReactText | ReactNode;
  className?: string;
  classNameContent?: string;
  isLoading?: boolean;
  isLoadingOverlay?: boolean;
  loadingOverlayClassName?: string;
  noData?: boolean;
  noDataComponent?: ReactNode;
  initWizardPage?: boolean;
}

const LayoutContent: FC<ILayoutContent> = ({
  title,
  className,
  classNameContent,
  isLoading = false,
  isLoadingOverlay = false,
  loadingOverlayClassName,
  noData = false,
  noDataComponent,
  children,
  initWizardPage,
}) => {
  const { isMobile, setShowMenu } = useDevice();
  const showContentLoading = isLoading && !isLoadingOverlay;
  // needed because sometimes content rendered firstly and then header and if we have BoxToolbar that render content in header it will not be rendered
  const [headerRendered, setHeaderRendered] = React.useState(false);

  return (
    <div className={cn(styles.component, className)}>
      <div className={cn(styles.header, initWizardPage && styles.headerInitWizardPageStyles)}>
        {isMobile && !initWizardPage && (
          <IconButton onClick={() => setShowMenu(true)} className={styles.burgerButton}>
            <MenuIcon />
          </IconButton>
        )}
        <HeaderContent title={title} setHeaderRendered={setHeaderRendered} className={styles.headerContent} />
      </div>
      {headerRendered && (
        <div className={cn(styles.content, classNameContent)}>
          <div id={LAYOUT_TOOLBAR_ID_MOBILE}/>
          {showContentLoading && <SpinnerPage className={styles.loading}/>}
          {!isLoadingOverlay && !isLoading && noData && <>{noDataComponent ? noDataComponent : <NoData/>}</>}
          {!showContentLoading && !noData && children}
          {isLoadingOverlay && isLoading && (
            <div className={cn(styles.loadingOverlay, loadingOverlayClassName)}>
              <Spinner className={styles.loading}/>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LayoutContent;
