import React, { FC } from 'react';

import VerifiedIconMui from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import cn from 'classnames';

import { i18nt } from 'translations/i18n';

import styles from 'components/VerifiedIcon/VerifiedIcon.module.scss';

export interface VerifiedIconProps {
  verified?: boolean;
}

const VerifiedIcon: FC<VerifiedIconProps> = ({verified}) =>
  <div className={cn(styles.component, verified && styles.verified)} title={verified ? i18nt('VERIFIED') : i18nt('NOT_ACTIVATED')}>
    {verified ? <VerifiedIconMui/> : <VerifiedOutlinedIcon/>}
  </div>;

export default VerifiedIcon;
