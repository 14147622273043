import React, { FC } from 'react';

import cn from 'classnames';
import i18n from 'i18next';

import AuthLanguageFooter from 'components/pages/Auth/AuthWrapper/AuthLanguageFooter/AuthLanguageFooter';

import styles from './AuthWrapper.module.scss';

interface AuthWrapperProps {
  className?: string;
}

const AuthWrapper: FC<AuthWrapperProps> = ({ children, className }) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.contentWrapper}>
      <div className={styles.content}>{children}</div>
    </div>
    <AuthLanguageFooter/>
  </div>
)

export default AuthWrapper;
