import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import * as yup from 'yup';

import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import InputPassword from 'components/controls/InputPassword/InputPassword';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import { AuthMode } from 'components/pages/Auth/LoginPage/LoginPage';
import SocialAuth from 'components/pages/Auth/SocialAuth/SocialAuth';
import useYupForm, { useValidationSchema } from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';
import { globalLocale } from 'utils/localization';

import styles from 'components/pages/Auth/RegistrationForm/RegistrationForm.module.scss';

const INITIAL_FORM_DATA = {
  password: '',
  passwordConfirmation: '',
  email: '',
  name: ''
}

export interface RegistrationFormProps {
  isInviteMode: boolean;
  setMode: (mode: AuthMode) => void;
}

const RegistrationForm: FC<RegistrationFormProps> = ({ isInviteMode, setMode }) => {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const urlQuery = queryString.parse(location.search);

  const {onRegistration} = useAuth();

  const yupCustomScheme = useValidationSchema()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required(),
        name: yup.string().required(),
        password: yupCustomScheme.passwordRegistration,
        passwordConfirmation: yupCustomScheme.passwordConfirmation,
      })),
    defaultValues: isInviteMode ? {...INITIAL_FORM_DATA, email: urlQuery.e} : INITIAL_FORM_DATA
  });

  const onSubmit = (data: any) => {
    onRegistration({...data, locale: globalLocale.key});
  };

  return (
    <div className={styles.component}>
      <div>
        <Box className={styles.box}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                {...register('email')}
                label="Email"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                className={styles.textField}
                disabled={isInviteMode}
              />
            </div>
            <div>
              <TextField
                {...register('name')}
                label={i18nt('NAME')}
                error={!!errors?.name?.message}
                helperText={errors?.name?.message}
                className={styles.textField}
              />
            </div>
            <div>
              <InputPassword
                label={i18nt('PASSWORD')}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
                className={styles.textField}
                type="password"
                showPassword={showPassword}
                triggerShowPassword={() => setShowPassword(!showPassword)}
                registerProps={register('password')}
              />
            </div>
            <div>
              <InputPassword
                label={i18nt('PASSWORD_CONFIRMATION')}
                error={!!errors?.passwordConfirmation?.message}
                helperText={errors?.passwordConfirmation?.message}
                className={styles.textField}
                type="password"
                showPassword={showPassword}
                triggerShowPassword={() => setShowPassword(!showPassword)}
                registerProps={register('passwordConfirmation')}
              />
            </div>
            <div className={styles.buttons}>
              <Button type="submit" variant="primary">
                {isInviteMode ? i18nt('LOGIN') : i18nt('SIGN_UP')}
              </Button>
              <Button variant="text" onClick={() => setMode('login')}>
                {i18nt('LOGIN')}
              </Button>
            </div>
          </form>
        </Box>
        <SocialAuth key="social_registration_page" hiddenButtonByDefault={false} showOnlyButtonWithoutOneTap />
      </div>
    </div>
  );
};

export default RegistrationForm;
