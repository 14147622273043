import React from 'react';

import Button, { ButtonProps } from 'components/Buttons/Button';
import { i18nt } from 'translations/i18n';

const ButtonEdit = ({variant = 'primary', children, startIcon = 'pencilLight', ...props}: ButtonProps) => (
  <Button variant={variant} startIcon={startIcon} {...props}>{children || i18nt('EDIT')}</Button>
);

export default ButtonEdit;
