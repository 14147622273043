import React, { useMemo, useState } from 'react';

import cn from 'classnames';

import ButtonAdd from 'components/Buttons/examples/ButtonAdd';
import FileUpload from 'components/FileUpload/FileUpload';
import FileUploadImage from 'components/FileUpload/FileUploadImage';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import MapEmbed from 'components/Map/MapEmbed';
import MapModal, { MapItemData } from 'components/OrganizationEditForm/MapModal/MapModal';
import OrganizationGallery, {
  OrganizationGalleryItem
} from 'components/OrganizationEditForm/OrganizationGallery/OrganizationGallery';
import GalleryItem from 'components/OrganizationEditForm/OrganizationGallerySection/GalleryItem/GalleryItem';
import { i18nt } from 'translations/i18n';

import styles from './OrganizationGallerySection.module.scss';

export const ORGANIZATION_GALLERY_DEFAULT_ITEMS: OrganizationGalleryItem[] = [
  {type: 'map', data: undefined},
  {type: 'image', data: undefined}
];

export interface IOrganizationGallerySection {
  items?: OrganizationGalleryItem[];
  onChange?: (value: OrganizationGalleryItem[]) => void;
  address?: string;
  readOnly?: boolean;
}
const OrganizationGallerySection = ({items: propsItems = ORGANIZATION_GALLERY_DEFAULT_ITEMS, onChange, address, readOnly }: IOrganizationGallerySection) => {
  const [showMapModal, setShowMapModal] = useState(false);
  const [showMapModalData, setShowMapModalData] = useState<MapItemData>();
  const items = useMemo(() => {
    if(readOnly) {
      return propsItems.filter((item) => item.data);
    }

    return propsItems;
  }, [propsItems]);

  if(items.length === 0) return null;

  const handleChange = (value: OrganizationGalleryItem[]) => {
    onChange?.(value);
  }

  const handleMapChange = (value?: MapItemData) => {
    const newItems = items.map((item) => {
      if (item.type === 'map') {
        return {
          ...item,
          data: value,
        }
      }

      return item;
    });

    handleChange(newItems);

  }

  const handleUploadImage = (imageUrl?: string) => {
    const newItems = items.map((item) => {
      if (item.type === 'image') {
        return {
          ...item,
          data: imageUrl,
        }
      }

      return item;
    });

    handleChange(newItems);
  };

  const handleEditMap = (data: any) => () => {
    setShowMapModal(true)
    setShowMapModalData({...data.data,address: data?.data?.address || address });
  }

  const handleRemoveMap = (data: any) => () => {
    setShowMapModalData({...data.data,address: '' });
    const newItems = items.map((item) => {
      if (item.type === 'map') {
        return {
          ...item,
          data: undefined,
        }
      }

      return item;
    });

    handleChange(newItems);
  };

  return (
    <>
      <OrganizationGallery
        items={items}
        onChange={handleChange}
        className={cn(styles.component, styles.readOnly)}
        //@ts-ignore
        renderItem={({data, draggableClassName}) => {
          if (data?.type === 'map') {
            const mapQuery = data?.data?.coordinates || data?.data?.address;

            return (
              <GalleryItem
                className={cn(draggableClassName, styles.presentationItem)}
                isEditable={mapQuery}
                onEdit={handleEditMap(data)}
                onAdd={handleEditMap(data)}
                onClear={handleRemoveMap(data)}
                placeHolderText={i18nt('ADD_MAP')}
                editLabel={i18nt('EDIT_MAP')}
                mapMode
                readOnly={readOnly}
              >
                {mapQuery && <MapEmbed mapAddress={mapQuery} className={styles.mapEmbed}/>}
              </GalleryItem>
            )
          }
          if (data.type === 'image') {
            return (
              <GalleryItem
                className={cn(draggableClassName, styles.presentationItem)}
                isEditable={data?.data}
                onClear={() => handleUploadImage(undefined)}
                hideActionsElements
                imageMode
                readOnly={readOnly}
              >
                <FileUploadImage
                  onChange={handleUploadImage}
                  value={data.data}
                  showDelete={false}
                  defaultSize={false}
                  readOnly={readOnly}
                />
              </GalleryItem>
            )
          }
        }}/>

      {showMapModal && <MapModal address={showMapModalData?.address} data={showMapModalData} onClose={() => setShowMapModal(false)}
        onChange={handleMapChange}/>}
    </>
  )
}

export default OrganizationGallerySection;
