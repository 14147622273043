import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';
import cn from 'classnames';

import styles from './Spinner.module.scss';

export interface ISpinner {
  className?: string;
}

const Spinner: FC<ISpinner> = ({ className }) => (
  <div className={cn(styles.component, className)}>
    <CircularProgress className={cn(styles.spinner)} />
  </div>
);

export default Spinner;
