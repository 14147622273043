import React from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

import { i18nt } from 'translations/i18n';

export type ShowNotificationType = {
  type: 'success' | 'info' | 'error' | 'warning' | 'warn';
  content?: ToastContent;
  options?: ToastOptions;
};

const showNotification = ({
  type,
  content,
  options,
}: ShowNotificationType): React.ReactText => {
  // inside of method because we need localization for messages
  const defaultMessages = {
    success: i18nt('EVERYTHING_IS_GREAT'),
    info: '',
    error: i18nt('AN_ERROR_OCCURRED'),
    warning: i18nt('ERROR_CONTACT_ADMIN'),
    warn: i18nt('ERROR_CONTACT_ADMIN'),
  };

  const message = content || defaultMessages[type];

  return toast[type](message, { ...options, className: 'toast-notification-element'});
};

export default showNotification;
