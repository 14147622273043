import { useState } from 'react';

import { apiUrl } from 'api/api';
import showNotification from 'components/Notifications/showNotification';

export interface useFileUploadResponse {
  file?: File;
  fileUrl?: string;
  uploadFile: (file: File) => Promise<string>;
  clearFile: () => void;
  isLoading: boolean;
  error?: string;
}

const useFileUpload = (showNotifications = false): useFileUploadResponse => {
  const [file, setFile] = useState<File | undefined>();
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleUploadFile = (file: File) => {
    setFile(file);
    setIsLoading(true);
    const formData = new FormData();

    formData.append('File', file);

    return fetch(`${apiUrl}/file`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setIsLoading(false);

        if (showNotifications) {
          showNotification({
            type: 'success',
            content: 'Файл успішно завантажено',
          });
        }
        setFileUrl(response.url);

        return response.url;
      })
      .catch((error) => {
        setError('File upload error');
        setIsLoading(false);
        setFileUrl(undefined);

        if (showNotifications) {
          showNotification({
            type: 'error',
            content: 'Виникла помилка з завантаженням файлу',
          });
        }
      });
  };

  const handleClearFile = () => {
    setFile(undefined);
    setFileUrl(undefined);
  };

  return {
    file,
    fileUrl,
    uploadFile: handleUploadFile,
    clearFile: handleClearFile,
    isLoading,
    error,
  };
};

export default useFileUpload;
