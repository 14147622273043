import React, { ChangeEvent, useState } from 'react';

import cn from 'classnames';

import Button from 'components/Buttons/Button';
import Input from 'components/Input/Input';
import MapEmbed from 'components/Map/MapEmbed';
import { useDevice } from 'providers/DeviceProvider';
import { i18nt } from 'translations/i18n';

import styles from 'components/Map/MapWithSearch.module.scss';

interface Props {
  className?: string;
  onChange?: (value: string) => void;
  searchBox?: boolean;
  // if we set inputValue, map will not be shown just prefilled input
  inputValue?: string;
  // if we set map address, map will be shown
  data?: string;
}

const MapWithSearch = ({onChange, className, data, ...props}: Props) => {
  const [inputValue, setInputValue] = React.useState<string>(props.inputValue || '');
  const [mapAddress, setMapAddress] = useState<string | undefined>(data);

  const { isMobile } = useDevice();
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMapAddress(undefined);

    const value = e.target.value;

    setInputValue(value);
  }
  
  const handleSearchByAddress = () => {
    setMapAddress(inputValue);
    onChange?.(inputValue);
  }

  return (
    <div className={cn(styles.component, className)}>
      <div className={styles.searchBox}>
        <Input label="Address" value={inputValue} onChange={handleChange} noMargin  className={styles.searchInput}/>
        <Button variant="primary" onClick={handleSearchByAddress}>{isMobile ? i18nt('SEARCH') : i18nt('SEARCH_ON_MAP')}</Button>
      </div>
      <div className={styles.mapBox}>
        {mapAddress &&
          <MapEmbed mapAddress={mapAddress} className={styles.mapEmbed}/>
        }

        {!mapAddress && (
          <div className={styles.mapPlaceholder}>
            <p>Please, enter address in input above</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MapWithSearch;
