import React, { FC } from 'react';

import cn from 'classnames';

import styles from './StepTitle.module.scss';

export interface StepTitleProps {
  number: number;
  title?: string;
  className?: string;
}

const StepTitle: FC<StepTitleProps> = ({number, title, className, children}) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.number}>{number}</div>
    {(title || children) && (
      <div className={styles.title}>{title || children}</div>
    )}
  </div>
);

export default StepTitle;
