import React from 'react';

import FileUpload, { FileUploadProps } from 'components/FileUpload/FileUpload';

const INPUT_PROPS = {accept: 'image/*'}

const FileUploadImage = (props: FileUploadProps) => (
  <FileUpload {...props} entity="image" inputProps={INPUT_PROPS} />
);

export default FileUploadImage;
