import { t } from 'i18next';

import {TranslationsKeys} from 'translations/i18n';

export const VALIDATION_ERRORS: Record<string, TranslationsKeys>  = {
  // server - general
  employee_already_added: 'VALIDATION.EMPLOYEE_ALREADY_ADDED',
  email_duplicated: 'VALIDATION.USER_WITH_THIS_EMAIL_ALREADY_REGISTERED',
  auth_not_correct_data: 'VALIDATION.AUTH_NOT_CORRECT_DATA',
  token_expired: 'VALIDATION.TOKEN_EXPIRED',

  // server - order https://barbergil.atlassian.net/browse/BAR-428
  not_schedule: 'VALIDATION.ORDER_NOT_SCHEDULE',
  not_working: 'VALIDATION.ORDER_NOT_WORKING_TIME',
  previous_time: 'VALIDATION.ORDER_PREVIOUS_TIME',
  break: 'VALIDATION.ORDER_BREAK',
  employee_busy: 'VALIDATION.ORDER_EMPLOYEE_BUSY',
  time_end_after_time_start: 'VALIDATION.ORDER_TIME_END_AFTER_TIME_START',

  // not confirmed where used
  // not_found: 'Не знайдено',

  // local
  EMAIL_INVALID: 'VALIDATION.EMAIL_INVALID',
  REQUIRED_FIELD: 'VALIDATION.REQUIRED_FIELD',

  // company create/edit
  domain_duplicated: 'VALIDATION.DOMAIN_DUPLICATED',
}

export interface ValidationMessageProps {
  code?: string;
  fallbackCode?: TranslationsKeys | false;
  defaultFallbackCode?: boolean;
  responseError?: {
    response: {
        data: {
          errors: {code: string}[]
        }
    }
  }
}
export const getValidationMessage = ({ code, defaultFallbackCode, fallbackCode}: ValidationMessageProps) => {

  const preDefinedValidationError = VALIDATION_ERRORS?.[code!]

  const fallbackMessageCode = defaultFallbackCode ? 'VALIDATION.ERROR_NOT_HANDLE' : fallbackCode;

  if(preDefinedValidationError) {
    return t(preDefinedValidationError);
  }

  if(fallbackMessageCode) {
    return t(fallbackMessageCode);
  }

  return undefined;
}

