import { ReactComponentElement } from 'react';

import { ReactComponent as addLayerLight } from 'assets/icons/addLayerLight.svg';
import { ReactComponent as arrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as arrowUp } from 'assets/icons/arrowUp.svg';
import { ReactComponent as calendarDays } from 'assets/icons/calendarDays.svg';
import { ReactComponent as cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as cancelBold } from 'assets/icons/cancelBold.svg';
import { ReactComponent as cancelThin } from 'assets/icons/cancelThin.svg';
import { ReactComponent as clients } from 'assets/icons/clients.svg';
import { ReactComponent as error } from 'assets/icons/error.svg';

import { ReactComponent as warning } from 'assets/icons/warning.svg';
import { ReactComponent as addRound } from './addRound.svg';
import { ReactComponent as addSquare } from './addSquare.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as chartMenu } from './chartMenu.svg';
import { ReactComponent as companyInfo } from './companyInfo.svg';
import { ReactComponent as employees } from './employees.svg';
import { ReactComponent as exit } from './exit.svg';
import { ReactComponent as fullScreen } from './fullScreen.svg';
import { ReactComponent as fullScreenExit } from './fullScreenExit.svg';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as list } from './list.svg';
import { ReactComponent as noData } from './noData.svg';
import { ReactComponent as noDataMaterial } from './noDataMaterial.svg';
import { ReactComponent as organizations } from './organizations.svg';
import { ReactComponent as scheduling } from './scheduling.svg';
import { ReactComponent as settings } from './settings.svg';
import { ReactComponent as theming } from './theming.svg';
import { ReactComponent as success } from 'assets/icons/success.svg';
import { ReactComponent as info } from 'assets/icons/info.svg';
import { ReactComponent as trash } from 'assets/icons/trash.svg';
import { ReactComponent as pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as pencilLight } from 'assets/icons/pencilLight.svg';
import { ReactComponent as pencilSolid } from 'assets/icons/pencilSolid.svg';
import { ReactComponent as visits } from 'assets/icons/visits.svg';
import { ReactComponent as copy } from 'assets/icons/copy.svg';
import { ReactComponent as feedbacks } from 'assets/icons/feedbacks.svg';
import { ReactComponent as filter } from 'assets/icons/filter.svg';
import { ReactComponent as filterFilled } from 'assets/icons/filterFilled.svg';
import { ReactComponent as dragIndicator } from 'assets/icons/dragIndicator.svg';
import { ReactComponent as chevronLeftLight } from 'assets/icons/chevronLeftLight.svg';
import { ReactComponent as chevronRightLight } from 'assets/icons/chevronRightLight.svg';
import { ReactComponent as chevronDownLight } from 'assets/icons/chevronDownLight.svg';
import { ReactComponent as handBackPointUp } from 'assets/icons/handBackPointUp.svg';
import { ReactComponent as googleColor } from 'assets/icons/googleColor.svg';
import { ReactComponent as plus } from 'assets/icons/plus.svg';
import { ReactComponent as plusRegular } from 'assets/icons/plusRegular.svg';
import { ReactComponent as floppyDiskRegular } from 'assets/icons/floppyDiskRegular.svg';
import { ReactComponent as rotateLeft } from 'assets/icons/rotateLeft.svg';
import { ReactComponent as pieChart } from 'assets/icons/pieChart.svg';
import { ReactComponent as table } from 'assets/icons/table.svg';

const icons = {
  noData,
  noDataMaterial,
  addRound,
  addSquare,
  home,
  employees,
  calendar,
  scheduling,
  list,
  chartMenu,
  exit,
  theming,
  organizations,
  companyInfo,
  settings,
  fullScreen,
  fullScreenExit,
  cancel,
  cancelThin,
  cancelBold,
  error,
  warning,
  success,
  info,
  trash,
  pencil,
  pencilLight,
  pencilSolid,
  visits,
  clients,
  copy,
  feedbacks,
  arrowDown,
  arrowUp,
  filter,
  filterFilled,
  addLayerLight,
  dragIndicator,
  chevronLeftLight,
  chevronRightLight,
  chevronDownLight,
  handBackPointUp,
  googleColor,
  plus,
  plusRegular,
  floppyDiskRegular,
  rotateLeft,
  pieChart,
  table,
  calendarDays,
};

export type IconsMapType = {
  [key in keyof typeof icons]: ReactComponentElement<any>;
};

export default icons;
