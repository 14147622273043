import React, { FC, forwardRef } from 'react';

import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import cn from 'classnames';

import VerifiedIcon from 'components/VerifiedIcon/VerifiedIcon';

import styles from './Input.module.scss';

export type InputProps = TextFieldProps & {
  // className?: string
  verifiedIcon?: boolean;
  verified?: boolean;
  noMargin?: boolean;
  formMargin?: boolean;
  readOnly?: boolean;
};

// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, InputProps>(({ className, size = 'small', verifiedIcon, verified, noMargin, formMargin, readOnly, ...props },ref) => {
  const inputProps = props.inputProps || {};

  if(verifiedIcon) {
    inputProps.endAdornment = <InputAdornment position="end" className={styles.adornment}><VerifiedIcon verified={verified}/></InputAdornment>;
  }

  if(readOnly) {
    inputProps.readOnly = true;
  }

  return (
    <TextField
      variant="outlined"
      className={cn(styles.component, size === 'small' && styles.inputSmall, formMargin && styles.formMargin, readOnly && styles.readOnly,className)}
      size={size}
      //@ts-ignore
      InputProps={inputProps}
      inputRef={ref}
      disabled={readOnly}

      {...props}

    />
  )
});

export default Input;
