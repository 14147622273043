import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import { App } from 'app/App';
import { initLogRocket } from 'utils/logrocketUtil';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

initLogRocket();

ReactModal.setAppElement('#root');
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

