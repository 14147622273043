import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';
import queryString from 'query-string';

import { getApiCodeError } from 'api/api';
import { apiGetUser, apiPatchUser, apiUserDeleteAccount, apiUserLogin, apiUserRegistration } from 'api/auth';
import showNotification from 'components/Notifications/showNotification';
import { VALIDATION_ERRORS } from 'constants/texts';
import {i18nt} from 'translations/i18n';
import { updateLocale } from 'utils/localization';
import { identifyLogRocket } from 'utils/logrocketUtil';

import SpinnerPage from '../../SpinnerPage/SpinnerPage';
import { UserDTO, UserRegistrationDTO } from '../../types';

type AuthContextType = {
  token: string | null;
  onLogin: ({ email, password }: { email: string; password: string }) => void;
  onRegistration: (data: UserRegistrationDTO) => void;
  setUser: (response: UserDTO, linkToRedirect?: string ) => void;
  loadUserData: (silentLoad?: boolean) => void;
  onLogout: () => void;
  user: UserDTO | null;
  isAuthPage: boolean;
  updateUserData: (data: Partial<UserDTO>) => Promise<UserDTO | void>;
  onDeleteAccount: () => void;
};
const AuthContextInitialState = {
  token: null,
  onLogin: _.noop,
  onRegistration: _.noop,
  loadUserData: _.noop,
  setUser: _.noop,
  onLogout: _.noop,
  user: null,
  isAuthPage: false,
  updateUserData: () => Promise.resolve({} as UserDTO),
  onDeleteAccount: _.noop,
};
const AuthContext = React.createContext<AuthContextType>(
  AuthContextInitialState
);

const AuthProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const urlQuery = queryString.parse(location.search);
  const inviteToken = urlQuery.init_token as string;
  const isInvitePage = location.pathname.includes('invite') || !!inviteToken;
  const isAuthPage =
    location.pathname === '/login' || location.pathname === '/registration';

  const isRestoreAccessPage = location.pathname === '/restore_access_confirm';

  if(inviteToken) {
    localStorage.setItem('token', inviteToken);
  }

  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [user, setUser] = useState<UserDTO | null>(null);

  const loadUserData = (silentLoad?: boolean) => {
    apiGetUser()
      .then((result) => {
        setUser(result?.data);

        if(!silentLoad) {
          identifyLogRocket(result?.data);

          // TODO:BE_SPIKE forceLanguage is spike for tests because BE not handle locale on registration
          updateLocale(localStorage.getItem('forceLanguage') || result?.data?.locale, false);
          if (isAuthPage && !isInvitePage) {
            navigate('/');
          }
        }
      })
      .catch(() => {
        showNotification({
          type: 'error',
          content: i18nt(VALIDATION_ERRORS.token_expired),
        });
        if(!isInvitePage) {
          handleLogout();
        }

      });
  }

  const updateUserData = (data: Partial<UserDTO>) => apiPatchUser(data)
    .then((result) => {
      // TODO: remove spike
      // Igor's spike. not correct DTO PATCH: https://app.stage.gil.com.ua/api/user
      // different GET and PATCH DTO
      setUser({...user, ...result?.data});
      showNotification({
        type: 'success',
        content: i18nt('PERSONAL_INFORMATION_UPDATE_SUCCESS'),
      });

      return {...user, ...result?.data};
    })
    .catch(() => {
      showNotification({
        type: 'error',
        content: i18nt('PERSONAL_INFORMATION_UPDATE_ERROR'),
      });
    })

  useEffect(() => {
    if (token && !isRestoreAccessPage) {
      loadUserData();

      return;
    }
    if (isAuthPage || isInvitePage) {
      return;
    }
    navigate('/login');
  }, []);

  const setupUserLocally = (response: UserDTO, link?: string) => {
    //@ts-ignore
    const token = response?.apiToken;

    setToken(token);
    setUser(response);
    localStorage.setItem('token', token);
    // @ts-ignore
    const origin = link || location.state?.from?.pathname || '/';

    navigate(origin);
  }
  const handleLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    apiUserLogin({ email, password })
      .then((response) => {
        setupUserLocally(response.data);
      })
      .catch(() => {
        showNotification({
          type: 'error',
          content: i18nt(VALIDATION_ERRORS.auth_not_correct_data),
        });
      });
  };

  const handleRegistration = (data: UserRegistrationDTO) => {
    apiUserRegistration(data)
      .then((response) => {
        setupUserLocally(response.data, '/addCompany');
      })
      .catch((e) => {
        showNotification({
          type: 'error',
          content: getApiCodeError(e) || i18nt(VALIDATION_ERRORS.auth_not_correct_data),
        });
      });
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleDeleteAccount = () => {
    apiUserDeleteAccount()
      .then(() => {
        showNotification({
          type: 'error',
        });
        handleLogout();
      }).catch(() => {
        showNotification({
          type: 'error',
        });
      });
  }

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onRegistration: handleRegistration,
    setUser: setupUserLocally,
    loadUserData,
    user,
    isAuthPage,
    updateUserData,
    onDeleteAccount: handleDeleteAccount,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isAuthPage && !isInvitePage && !user ? (
        <SpinnerPage className="AuthProvider_provider" />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
