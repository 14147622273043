import React, { memo, ReactNode, ReactText, useEffect } from 'react';

import cn from 'classnames'

import { LAYOUT_TOOLBAR_ID } from 'components/LayoutContent/LayoutContent';

import styles from './HeaderContent.module.scss';

type Props = {
  className?: string;
  title: ReactText | ReactNode;
  setHeaderRendered: (value: boolean) => void;
}

const HeaderContent = ({ className, title, setHeaderRendered }: Props) => {

  useEffect(() => {
    setHeaderRendered(true);
  }, []);

  return (
    <div className={cn(styles.component, className)}>
      <div className={styles.headerTitle} data-id="LayoutContent.headerTitle">
        {title}
      </div>
      <div id={LAYOUT_TOOLBAR_ID} className={styles.toolbar}/>
    </div>
  );
};

export default memo(HeaderContent);
// export default HeaderContent ;
