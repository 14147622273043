import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import logo from 'assets/images/logo.png';

import styles from 'components/Logo/Logo.module.scss';

const Logo = () => (
  <NavLink className={styles.component} to="/login">
    <img src={logo}/>
    <div>
      <p>GIL</p>
      <span>Gather in life</span>
    </div>
  </NavLink>
);

export default Logo;
