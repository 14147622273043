import {format } from 'date-fns';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import { enGB, fr, uk } from 'date-fns/locale';
import enUS from 'date-fns/locale/en-US';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import startOfWeek from 'date-fns/startOfWeek';

// begin of timezones locales
// import enlocale from './dateLocales/enlocale.json';
// import ualocale from './dateLocales/uklocale.json';

import { formatInTimeZone } from 'date-fns-tz';

import { globalLocale } from 'utils/localization';

export const locales = {
  'en-US': enUS,
};

export const dateFnsLocaleMapper = {
  ua: uk,
  en: enGB,
  fr,
}

export const dateFormats = {
  day: 'dd',
  dayName: 'EEEE',
  dayName2Letters: 'EEEEEE', // 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'
  shortDayName: 'EEE',
  time: 'HH:mm',
  month: 'LLLL',
  monthLead: 'MMMM',
  date: 'dd-MM-yyyy',
  dateView: 'yyyy-MM-dd',
  dayMonthName: 'dd MMMM',
  dateAndTime: 'dd-MM-yyyy HH:mm',
}

export const DATE_AND_TIME = `${dateFormats.date} ${dateFormats.time}`;

export const dateFormat = (date: Date, formatString?: any): string =>
  format(date, formatString || dateFormats.date, {
    locale: globalLocale.locale // or global.__localeId__
  })

export type dateFormatInTZArgs = {
  date: Date,
  formatString?: any,
  tz?: string
}
export const dateFormatInTZ = ({date, formatString, tz}: dateFormatInTZArgs) =>
  formatInTimeZone(date, tz || 'Europe/Kyiv', formatString || dateFormats.date, {locale: globalLocale.locale })

export const getWeekDays = (): string[] => {
  // @ts-ignore
  const locale = globalLocale.locale;
  const now = new Date();
  const weekDays: string[] = [];
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });

  eachDayOfInterval({ start, end }).forEach(day => {
    weekDays.push(format(day, dateFormats.dayName2Letters, { locale }));
  });

  return weekDays;
};

const EXCLUDED_TIMEZONES = [
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Volgograd',
  'Europe/Samara',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Astrakhan',
  'Europe/Ufa',
  'Europe/Yekaterinburg',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Europe/Kaliningrad',
  'Europe/Minsk'
];

const getTimeZoneName = (timezone: string) => {
  if(timezone === 'Europe/Kiev') {
    return 'Europe/Kyiv';
  }

  return timezone;
}

const getAllTimezonesWithOffset = (): {timezone: string, offset: string}[] =>  {
  const timezones = [];

  //@ts-ignore
  for (const timezone of Intl.supportedValuesOf('timeZone')) {
    const tz = new Date().toLocaleTimeString('en', {
      timeZone: timezone,
      timeZoneName: 'longOffset'
    })

    if (EXCLUDED_TIMEZONES.includes(timezone)) {
      continue;
    }

    const offsetOrigin = tz.split(' ')[2]

    const offset = offsetOrigin.replace(/GMT/, '');

    timezones.push({ timezone: getTimeZoneName(timezone), offset: offset || '00:00' });
  }

  return timezones;
}

// need grouped timezones
const timezones = getAllTimezonesWithOffset();
const groupedTimezones = timezones.reduce((acc: Record<string, {timezone: string, offset: string}>, timezone) => {
  const offset = timezone.offset;

  if (!acc[offset]) {
    //@ts-ignore
    acc[offset] = [];
  }
  //@ts-ignore
  acc[offset].push(timezone);

  return acc;
}, {});

export const timezonesList = Object.values(groupedTimezones).flat()

export const timeZoneOptions = timezonesList.map(timezone => ({
  label: `${timezone.timezone.replaceAll('_', ' ')} (${timezone.offset}) `,
  value: timezone.timezone
}));

export const timezoneCurrent = getTimeZoneName(Intl.DateTimeFormat().resolvedOptions().timeZone)

