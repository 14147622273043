import React from 'react';

import SelectMy, { SelectProperties } from 'components/Selects/SelectMy';
import { AutocompleteOptionType } from 'components/Selects/types';
import { i18nt } from 'translations/i18n';
import { timeZoneOptions } from 'utils/dateUtil';

type Props = SelectProperties & {
  value: string;
  onChange: (value: string) => void;
}

const TimezoneAutocomplete = ({ value: propValue, onChange, ...props }: Props) => {
  const handleChange = (option: AutocompleteOptionType | null) => {
    onChange(option?.value as string);
  }

  const handleGroupBy = (option: AutocompleteOptionType) => {
    const groupName = option.label.split('/')[0];

    // return groupName !== option.label ? groupName : null;

    return groupName;
  }

  const handleGetOptionLabel = (option: AutocompleteOptionType) => {
    const group = option.label.split('/')[0];

    const optionName = option.label.substr(group.length + 1)

    return optionName !== '' ? optionName : option.label;
  }

  return (
    //@ts-ignore
    <SelectMy<{}, string>
      onChange={handleChange}
      options={timeZoneOptions}
      value={propValue}
      label={i18nt('TIMEZONE')}
      //@ts-ignore
      groupBy={handleGroupBy}
      //@ts-ignore
      getOptionLabel={handleGetOptionLabel}
      formMargin
      isPrimitiveValue
      {...props}
    />
  );
};

export default TimezoneAutocomplete;
