import { useEffect, useMemo, useState } from 'react';

import { ApiRequest, AutocompleteOptionType, AutocompleteOptionValue } from 'components/Selects/types';

type Args<OPTION, OPTION_VALUE> = {
  options?: OPTION[],
  apiRequest?: ApiRequest<OPTION>,
  value?: OPTION | OPTION[] | OPTION_VALUE | OPTION_VALUE[] | null,
  isPrimitiveValue?: boolean,
  multiple?: boolean,
}

const useAutocompleteData = <
  T,
  OPTION_VALUE = AutocompleteOptionValue,
  OPTION extends AutocompleteOptionType<T, OPTION_VALUE> = AutocompleteOptionType<T, OPTION_VALUE>
>({options: optionsProps, apiRequest, value, isPrimitiveValue, multiple}: Args<OPTION, OPTION_VALUE>) => {

  const isApiRequest = !!apiRequest && !optionsProps;

  const [isLoading, setIsLoading] = useState<boolean>(isApiRequest);
  const [_options, setOptions] = useState<OPTION[]>(optionsProps || []);

  useEffect(() => {
    if(optionsProps) {
      setOptions(optionsProps);
    }
  }, [optionsProps]);

  useEffect(() => {
    if(apiRequest) {
      setIsLoading(true);
      apiRequest().then((options) => {
        setOptions(options);
        setIsLoading(false);
      });
    }
  }, [apiRequest])

  const _value = useMemo(() => {
    if(isPrimitiveValue) {
      if(Array.isArray(value)) {
        //@ts-ignore
        return _options.filter((o) => value?.includes(o!.value));
      }

      return _options.find((o) => o.value === value);
    }

    return value;
  },[value, _options]);

  const typedValue = multiple ? _value as OPTION[] : _value as OPTION;

  return {
    isLoading,
    options: _options,
    value: typedValue || undefined,
  }
};

export default useAutocompleteData;
