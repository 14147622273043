import React, { useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ButtonsWrapper from 'components/Buttons/ButtonsWrapper/ButtonsWrapper';
import ButtonAdd from 'components/Buttons/examples/ButtonAdd';
import ButtonCancel from 'components/Buttons/examples/ButtonCancel';
import ButtonDelete from 'components/Buttons/examples/ButtonDelete/ButtonDelete';
import MapMarker from 'components/Map/MapMarker/MapMarker';
import MapWithSearch from 'components/Map/MapWithSearch';
import ModalFooter from 'components/Modal/components/ModalFooter/ModalFooter';
import Modal from 'components/Modal/Modal';
import { i18nt } from 'translations/i18n';

import styles from './MapModal.module.scss';

export type MapItemData = {
  address?: string;
  coordinates?: string;
}
interface Props {
  open?: boolean;
  onClose?: () => void;
  address?: string;
  onChange?: (value: MapItemData | undefined) => void;
  data?: MapItemData
}

const MapModal = ({ open = true, onClose, address, onChange, data }: Props) => {
  const [ mapData, setMapData ] = useState<MapItemData>();
  const [activeTab, setActiveTab] = useState<'address' | 'manual'>(data?.coordinates ? 'manual' : 'address');

  useEffect(() => {
    setMapData(data);
  }, [data]);
  const handleAdd = () => {
    onChange?.(mapData);
    onClose?.();
  }

  const handleDelete = () => {
    onChange?.(undefined);
    onClose?.();
  }

  const handleMapAddress = (value: string) => {
    setMapData({
      address: value,
      coordinates: '',
    });
  }
  const handleMapMarkerOnChange = (value: string) => {
    setMapData({
      address: '',
      coordinates: value,
    });
  }

  return (
    <Modal open={open} onClose={onClose} title={i18nt('ADD_ORGANIZATION_ADDRESS')} className={styles.component}>
      <Tabs
        className={styles.tabs}
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        aria-label="basic tabs example"
      >
        <Tab label={i18nt('ADDRESS')} value="address" />
        <Tab label={i18nt('POINT_ON_THE_MAP')} value="manual" />
      </Tabs>
      {activeTab === 'address' && (
        <MapWithSearch data={data?.address} inputValue={mapData?.coordinates ? '' : address} onChange={handleMapAddress} className={styles.mapWithSearch}/>
      )}
      {activeTab === 'manual' && (
        <MapMarker onChange={handleMapMarkerOnChange}/>
      )}

      <ModalFooter className={styles.footer}>
        <ButtonDelete onClick={handleDelete}/>
        <ButtonsWrapper>
          <ButtonCancel onClick={onClose}>Close</ButtonCancel>
          <ButtonAdd onClick={handleAdd} disabled={!mapData}/>
        </ButtonsWrapper>
      </ModalFooter>
    </Modal>
  )

}

export default MapModal;
