import React, { ReactNode, useEffect, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete as AutocompleteMUI, PaperProps } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import cn from 'classnames';

import Input from 'components/Input/Input';
import {
  AutocompleteOptionType,
  AutocompleteOptionValue,
  SelectBaseProps,
  SelectProps,
} from 'components/Selects/types';
import useAutocompleteData from 'components/Selects/useAutocompleteData';
import { i18nt } from 'translations/i18n';

import styles from './Select.module.scss';

export type OptionType<T = string> = {
  value: T;
  label: string;
  sys?: {
    createNew?: boolean;
  };
};

export type SelectProperties<OPTION extends any = any> = {
  className?: string;
  disabled?: boolean;
  disablePortal?: boolean;
  isLoading?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  size?: 'small' | 'medium';
  formMargin?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  loading?: boolean;
  disableCloseOnSelect?: boolean;
  blurOnSelect?: boolean;
  disableClearable?: boolean;
  disableTextField?: boolean;
  open?: boolean;
  createNewAvailable?: boolean;
  customOptionRenderer?: (params:  {option: OPTION, other: React.HTMLAttributes<HTMLLIElement>, selected: boolean  }) => React.ReactNode;
};

export type BaseSelectPropsConfiguration<T, OPTION_VALUE, OPTION = AutocompleteOptionType<T, OPTION_VALUE>> = SelectProperties<AutocompleteOptionType<T, OPTION_VALUE>> & {
  getOptionLabel?: (option: OPTION) => string;
  groupBy?: (option: OPTION) => string;
};

const SelectBase = <
  T,
  OPTION_VALUE = AutocompleteOptionValue,
  OPTION extends OptionType<OPTION_VALUE> = AutocompleteOptionType<T, OPTION_VALUE>
>({
    className,
    disablePortal = true,
    error,
    helperText,
    label,
    size,
    onChange,
    formMargin,
    disableTextField,
    open: propsOpen,
    createNewAvailable,
    customOptionRenderer,
    ...props
  }: SelectBaseProps<T, OPTION_VALUE>) => {
  const ref = React.useRef<HTMLInputElement>(null);
  /*
   * handling open state needed for working with create new option
   * */
  const [open, setOpen] = React.useState(propsOpen || false);

  useEffect(() => {
    if (propsOpen === undefined) return;
    setOpen(propsOpen);
  }, [propsOpen]);

  useEffect(() => {
    if (open && ref?.current) {
      ref.current.querySelector('input')?.focus();
    }
  }, [open, props.value]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const PaperComponent = ({ children, ...props }: JSX.IntrinsicAttributes & PaperProps) => (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        {...props}
        // className={cn(styles.paper, disablePortal && styles.disablePortal, props.className, {
        //   [styles.isDynamicSelect]: isDynamicSelect,
        // })}
      >
        {children}
        {/*{ListFooter}*/}
      </Paper>
    </ClickAwayListener>
  );

  {
    /*// Loading not working*/
  }

  return (
    <AutocompleteMUI<OPTION>
      ref={ref}
      disablePortal={disablePortal}
      popupIcon={<ExpandMoreIcon />}
      // renderInput={(params) => <TextField {...params} label="Movie" />}
      renderInput={params => (
        <Input
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          size={size}
          formMargin={formMargin}
          name={`autocomplete-${label}`}
          readOnly={disableTextField}
          onClick={() => setOpen(!open)}
          onChange={(...args) => {
            if (!open) setOpen(true);
          }}
        />
      )}
      onClose={() => setOpen(false)}
      // @ts-ignoregetOptionLabel don't recognize option prop
      getOptionLabel={option => option.label}
      // @ts-ignoreonChange don't recognize multiple prop
      onChange={(event, value) => onChange(value, event)}
      className={cn('Autocomplete_component', styles.component, className)}
      filterOptions={(options, state) => {
        const optionsFiltered = options.filter(option =>
          state.getOptionLabel(option).toLowerCase().includes(state.inputValue.toLowerCase()),
        );

        if (createNewAvailable && optionsFiltered.length === 0 && state.inputValue) {
          return [{ label: `${state.inputValue}`, value: state.inputValue, sys: { createNew: true } } as OPTION];
        }

        return optionsFiltered;
      }}
      disableCloseOnSelect={props.multiple}
      {...props}
      key={`some-hash-${label}-${props.options.length}`}
      open={open}
      renderOption={(props, option, { selected }) => {
        if (!option) return null;

        if (customOptionRenderer) {
          //@ts-ignore
          return customOptionRenderer({ other: props, option, selected  });
        }

        if (option?.sys?.createNew) {
          return (
            <li {...props} key={`option-${option.value}`}>
              {i18nt('CREATE')}
              {` ${option.label}`}
            </li>
          );
        }

        return (
          <li {...props} key={`option-${option.value}`}>
            {option.label}
          </li>
        );
      }}
      // PaperComponent={PaperComponent}
    />
  );
};

const SelectMy = <T, OPTION_VALUE = AutocompleteOptionValue>({ ...props }: SelectProps<T, OPTION_VALUE>) => {
  const { options: propOptions, apiRequest: apiRequestProp, value: propValue, isPrimitiveValue, ...otherProps } = props;

  const { options, value, isLoading } = useAutocompleteData<T, OPTION_VALUE>({
    options: propOptions,
    apiRequest: apiRequestProp,
    value: propValue,
    isPrimitiveValue,
    multiple: otherProps.multiple,
  });

  if (otherProps.multiple) {
    return (
      <SelectBase<T, OPTION_VALUE>
        {...otherProps}
        options={options}
        value={value as AutocompleteOptionType<T, OPTION_VALUE>[]}
        isLoading={isLoading}
      />
    );
  }

  return (
    <SelectBase<T, OPTION_VALUE>
      {...otherProps}
      options={options}
      value={value as AutocompleteOptionType<T, OPTION_VALUE>}
      isLoading={isLoading}
    />
  );
};

export default SelectMy;
//
// type A = {
//   year: number;
// }
// type CUSTOM_OPTION_TYPE = number;
// const Playground = () => {
//   const options = [{label: 'label_string', value: 123, year: 1233}];
//   const onChangeSingle = (value: AutocompleteOptionType<A> | null) => {};
//   const onChangeMultiple = (value: AutocompleteOptionType<A>[] | null) => {};
//
//   const fakeApiRequest = () => Promise.resolve(options);
//
//   return (
//     <>
//       <SelectBase<A, CUSTOM_OPTION_TYPE>
//         options={options}
//         onChange={onChangeSingle}
//         // value={123}
//         // isPrimitiveValue
//       />
//       <SelectBase<A, CUSTOM_OPTION_TYPE>
//         options={options}
//         onChange={onChangeMultiple}
//         multiple
//       />
//
//       {/*api request*/}
//       <Select<A>
//         apiRequest={fakeApiRequest}
//         onChange={onChangeSingle}
//       />
//       <Select<A>
//         apiRequest={fakeApiRequest}
//         onChange={onChangeMultiple}
//         multiple
//       />
//     </>
//   )
// }
