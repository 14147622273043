import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import queryString from 'query-string';
import * as yup from 'yup';

import {getApiCodeError} from 'api/api';
import { apiUserInvitationSetPassword } from 'api/auth';
import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import InputPassword from 'components/controls/InputPassword/InputPassword';
import showNotification from 'components/Notifications/showNotification';
import useYupForm, { useValidationSchema } from 'hooks/useYupForm/useYupForm';
import { i18nt } from 'translations/i18n';

import { useAuth } from '../AuthProvider';

import styles from 'components/pages/Auth/InvitationForm/InvitationForm.module.scss';

const INITIAL_FORM_DATA = {
  password: '',
  passwordConfirmation: '',
  email: '',
  name: ''
}

const InvitationForm = () => {
  const location = useLocation();
  const { setUser } = useAuth();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const urlQuery = queryString.parse(location.search);
  const inviteToken = urlQuery.invitation_token as string;

  const yupCustomScheme = useValidationSchema()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useYupForm({
    resolver: yupResolver(yup
      .object({
        email: yupCustomScheme.email.required(),
        password: yupCustomScheme.passwordRegistration,
        passwordConfirmation: yupCustomScheme.passwordConfirmation,
      })),
    defaultValues: {...INITIAL_FORM_DATA, email: urlQuery.e}
  });

  const onSubmit = (data: any) => {
    apiUserInvitationSetPassword({...data, token: inviteToken})
      .then(result => {
        setUser(result.data);
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          content: getApiCodeError(error),
        });
      })
  };

  return (
    <Box className={styles.component}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            {...register('email')}
            label="Email"
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            className={styles.textField}
            disabled
          />
        </div>
        <div>
          <InputPassword
            label={i18nt('PASSWORD')}
            error={!!errors?.password?.message}
            helperText={errors?.password?.message}
            className={styles.textField}
            showPassword={showPassword}
            triggerShowPassword={() => setShowPassword(!showPassword)}
            type="password"
            registerProps={register('password')}
          />
        </div>
        <div>
          <InputPassword
            label={i18nt('PASSWORD_CONFIRMATION')}
            error={!!errors?.passwordConfirmation?.message}
            helperText={errors?.passwordConfirmation?.message}
            className={styles.textField}
            showPassword={showPasswordConfirmation}
            triggerShowPassword={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
            type="password"
            registerProps={register('passwordConfirmation')}
          />
        </div>
        <div className={styles.buttons}>
          <Button type="submit" variant="primary">
            Увійти
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default InvitationForm;
