import React, { FC } from 'react';

import cn from 'classnames';

import Icon from 'components/Icon/Icon';

import styles from './NoData.module.scss';

export interface NoDataProps {
  title?: string;
  className?: string;
}

const NoData: FC<NoDataProps> = ({ title = 'No date', className }) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.imageWrapper}>
      <Icon name="noDataMaterial" className={styles.icon}/>
    </div>
    <div className={styles.title}>{title}</div>
  </div>
);

export default NoData;
