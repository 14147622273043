import React, { FC } from 'react';

import cn from 'classnames';

import ButtonAdd from 'components/Buttons/examples/ButtonAdd';
import ButtonEdit from 'components/Buttons/examples/ButtonEdit';
import Icon from 'components/Icon/Icon';

import styles from './GalleryItem.module.scss';

interface Props {
  className?: string;
  isEditable?: boolean;
  onEdit?: () => void;
  onAdd?: () => void;
  onClear?: () => void;
  mapMode?: boolean;
  placeHolderText?: string;
  hideActionsElements?: boolean;
  imageMode?: boolean;
  editLabel?: string;
  readOnly?: boolean;
}

const GalleryItem: FC<Props> = ({
  className,
  isEditable,
  onEdit,
  mapMode,
  placeHolderText,
  onAdd,
  hideActionsElements = false,
  onClear,
  imageMode,
  editLabel,
  readOnly,
  children,
}) => (
  <div
    className={cn(
      styles.component,
      className,
      mapMode && isEditable && styles.mapMode,
      imageMode && styles.imageMode,
      (!readOnly && isEditable) && styles.editMode,
      readOnly && styles.readOnly,
    )}
  >
    {!readOnly && !hideActionsElements && isEditable && (
      <div className={cn(styles.editControl)}>
        <ButtonEdit onClick={onEdit}>{editLabel}</ButtonEdit>
      </div>
    )}

    {!readOnly && !hideActionsElements && !isEditable && (
      <div className={cn(styles.editControl)}>
        <div>
          <ButtonAdd onClick={onAdd}>{placeHolderText}</ButtonAdd>
        </div>
      </div>
    )}

    {!readOnly && isEditable && (
      <div className={styles.clearControl}>
        <Icon name="cancel" onClick={onClear} className={styles.clearControlIcon} />
      </div>
    )}

    <div className={styles.content}>{children}</div>
  </div>
);

export default GalleryItem;
