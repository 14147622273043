import React from 'react';
import { Tooltip as TippyTooltip, TooltipProps as TippyTooltipProps } from 'react-tippy';

import cn from 'classnames';

import 'react-tippy/dist/tippy.css';
import styles from './Tooltip.module.scss'

export interface TooltipProps {
  arrow?: boolean;
  className?: string;
  content?: React.ReactNode;
  position?: TippyTooltipProps['position'];
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  arrow = true,
  children,
  className ,
  content,
  position = 'top',
  disabled
}) => {
  if (!content) {
    return <>{children}</>;
  }

  return (
    //@ts-ignore
    <TippyTooltip
      arrow={arrow}
      html={<>{content}</>}
      position={position}
      className={cn(styles.component, className)}
      animation="shift"
      disabled={disabled}
    >
      {children}
    </TippyTooltip>
  );
};

export default Tooltip;
