import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import queryString from 'query-string';

import { apiCheckInvitationToken } from 'api/auth';

type Args = {
  query: Record<string, any>;
  execute?: boolean;
};

const INITIAL_STATE = {
  loading: false,
};

const useInvitationToken = ({ query, execute }: Args) => {
  const navigate = useNavigate();
  const invitationToken = query.invitation_token;
  const invitationEmail = query.e;

  const [loading, setLoading] = useState(Boolean(invitationToken && execute));

  useEffect(() => {
    if (!execute) return;
    apiCheckInvitationToken({
      invitationToken: invitationToken,
      email: invitationEmail,
    })
      .then(result => {
        console.log('then');
      })
      .catch(error => {
        console.log('catch');
        console.log({ error });

        let hashNotFound = false;

        error?.response?.data?.errors?.forEach((error: any) => {
          if (error?.code === 'hash_not_found') {
            hashNotFound = true;
          }
        });
        const emailQuery = queryString.stringify({ e: invitationEmail });
        const searchQuery = emailQuery ? `?${emailQuery}` : '';

        if (hashNotFound) {
          window.location.href = `/login${searchQuery}`;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return execute
    ? {
      loading,
      setLoading,
    }
    : INITIAL_STATE;
};

export default useInvitationToken;
