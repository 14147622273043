import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import cn from 'classnames';
import queryString from 'query-string';

import Logo from 'components/Logo/Logo';
import AuthWrapper from 'components/pages/Auth/AuthWrapper/AuthWrapper';
import useInvitationToken from 'components/pages/Auth/hooks/useInvitationToken';
import InvitationForm from 'components/pages/Auth/InvitationForm/InvitationForm';
import LoginForm from 'components/pages/Auth/LoginForm/LoginForm';
import RegistrationForm from 'components/pages/Auth/RegistrationForm/RegistrationForm';
import RestoreConfirmationForm from 'components/pages/Auth/RestoreConfirmationForm/RestoreConfirmationForm';
import RestoreForm from 'components/pages/Auth/RestoreForm/RestoreForm';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';

import styles from './LoginPage.module.scss';

// useImperativeHandle
// https://github.com/react-hook-form/react-hook-form/issues/566
//https://ru.reactjs.org/docs/hooks-reference.html#useimperativehandle

type LoginPageProps = {
  invite?: boolean;
  mode?: AuthMode;
}
export type AuthMode = 'login' | 'registration'| 'invitation' | 'restoreAccess' | 'restoreAccessConfirm';

const LoginPage: FC<LoginPageProps> = ({ invite: inviteProp, mode: modeProp}) => {
  const location = useLocation();

  // const [mode, setMode] = useState<AuthMode>(modeProp || 'login');
  const [mode, setMode] = useState<AuthMode>(modeProp || 'login');
  const urlQuery = queryString.parse(location.search);

  const isInviteMode = Boolean(inviteProp && urlQuery.e);

  const {loading} = useInvitationToken({query: urlQuery, execute: modeProp === 'invitation'});

  if(loading) return <SpinnerPage/>;

  return (
    <AuthWrapper className={cn(styles.component, styles.theme2)}>
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      {mode === 'login' && (
        <LoginForm
          isInviteMode={isInviteMode}
          setMode={setMode}
        />
      )}
      {mode === 'registration' && (
        <RegistrationForm
          isInviteMode={isInviteMode}
          setMode={setMode}
        />
      )}
      {mode === 'invitation' && (
        <InvitationForm />
      )}
      {mode === 'restoreAccess' && (
        <RestoreForm setMode={setMode}/>
      )}
      {mode === 'restoreAccessConfirm' && (
        <RestoreConfirmationForm />
      )}
    </AuthWrapper>
  );
};

export default LoginPage;
