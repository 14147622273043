// export const prepareQueryFilters = (filters: Record<string, any>): string => {
//   const queryFilters = Object.entries(filters)
//     .map(([key, value]) => {
//       if (Array.isArray(value)) {
//         return value.map((v) => `${key}=${v}`).join('&');
//       }
//
//       return `${key}=${value}`;
//     })
//     .join('&');
//
//   return queryFilters;
// }

import _ from 'lodash';

const isObject = (variable: any) => variable !== null && typeof variable === 'object' && !Array.isArray(variable);

export const prepareQueryFilters = (filters: Record<string, any>): Record<string, string> =>
  Object.entries(filters)
    .reduce((acc,[key, value]) => {
      if (Array.isArray(value)) {
        const _value = value.filter(i => i).join();

        if(!_value) return acc;

        return {
          ...acc,
          [key]: _value,
        }
      }
      if (isObject(value)) {
        return acc;
      }

      if(!value) {
        return acc;
      }

      return {
        ...acc,
        [key]: value,
      };
    }, {} as Record<string, string>);

export const clearObjectFromEmptyValues = (filters: Record<string, any>): Record<string, string> => {
  const cleanData = {...filters};

  Object.keys(cleanData).forEach(key => {
    const value = cleanData[key];

    if(value === undefined || value === null || value === '' || _.isEmpty(value)) {
      delete cleanData[key];
    }
  });

  return cleanData;
}

export const prepareQueryFiltersToString = (filters: Record<string, any>): string => {
  const queryFilters = Object.entries(filters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${v}`).join('&');
      }

      return `${key}=${value}`;
    })
    .join('&');

  return queryFilters;
};
