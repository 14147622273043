import React from 'react';
import { ToastContainer } from 'react-toastify';

import cn from 'classnames';

import Icon from 'components/Icon/Icon';

import 'react-toastify/dist/ReactToastify.css';
import { useDevice } from 'providers/DeviceProvider';

import styles from './Notifications.module.scss';

const CloseButton = ({ closeToast }: any) => {
  const { isMobile } = useDevice();

  return (
    <div data-testId="closeNotification" onClick={closeToast}>
      <Icon
        name={isMobile ? 'cancel' : 'cancelBold'}
        // onClick={closeToast}
        className={styles.icon}
        // data-testId="closeNotification"
      />
    </div>

  );
}

const Notifications = () => (
  <ToastContainer
    // toastClassName={styles.toast}
    // bodyClassName={styles.body}
    className={cn('Notifications_component', styles.component)}
    closeOnClick={false}
    draggable={false}
    closeButton={CloseButton}
  />
);

export default Notifications;
