import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import cn from 'classnames';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { MapItemData } from 'components/OrganizationEditForm/MapModal/MapModal';

import styles from './OrganizationGallery.module.scss';

export type OrganizationGalleryItem = {
  type: 'image' | 'map';
  data?: string | MapItemData;
}

const reorder = (list: {id: string, content: any}[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

type RenderedItem = OrganizationGalleryItem & {
  style?: string,
  isDragging?: boolean,
  draggableClassName?: string,
}

interface OrganizationGalleryProps<T> {
  className?: string;
  items: T[],
  onChange?: (items: T[]) => void;
  renderItem: (item: RenderedItem[]) => ReactNode;
}

const convertItemsToList = (items: any) => {
  const itemsArr = [...items];

  return itemsArr.map((item: any) => ({id: uuid(), content: item}));
}

const OrganizationGallery = <T,>({className, items, renderItem, onChange}: OrganizationGalleryProps<T>) => {

  const [localItems, setLocalItems] = useState(convertItemsToList(items));

  useEffect(() => {
    if(_.isEqual(items, localItems.map(item => item.content))) return;
    setLocalItems(convertItemsToList(items));
  }, [items]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      localItems,
      result.source.index,
      result.destination.index
    );

    setLocalItems(items);
    onChange?.(items.map(item => item.content));
  }

  return (
    <div className={cn(styles.component, className)}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={cn(styles.content, snapshot.isDraggingOver && styles.draggingBox)}
              {...provided.droppableProps}
            >
              {localItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => {

                    const defaultItemProps = {
                      className: cn(styles.item, styles.draggable),
                      ref: provided.innerRef,
                      ...provided.draggableProps,
                      ...provided.dragHandleProps,
                      style: provided.draggableProps.style as CSSProperties,
                      isDragging: snapshot.isDragging,
                    }
                    const itemProps = {
                      className: cn(styles.item, styles.draggable, snapshot.isDragging && styles.draggingItem),
                      draggableClassName: cn(snapshot.isDragging && styles.draggingItem),
                      isDragging: snapshot.isDragging,
                    }

                    return (
                      <div {...defaultItemProps}>
                        {/*@ts-ignore*/}
                        {renderItem({ ...itemProps, data: item.content })}
                      </div>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>

  );
}

export default OrganizationGallery;
