import React from 'react';

import Button, { ButtonProps } from 'components/Buttons/Button';
import { i18nt } from 'translations/i18n';

const ButtonCancel = ({variant = 'secondary', children, startIcon = 'cancel', ...props}: ButtonProps) => (
  <Button variant={variant} startIcon={startIcon} {...props}>{children || i18nt('CANCEL')}</Button>
);

export default ButtonCancel;
