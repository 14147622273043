import React, { FC, Suspense } from 'react';

import SpinnerPage from 'components/SpinnerPage/SpinnerPage';

type Props =  {
  lazy?: boolean;
}

const CustomRoute: FC<Props> = ({lazy, children}) => {

  if(lazy) {
    return (
      <Suspense
        fallback={<SpinnerPage/>}
      >
        {children}
      </Suspense>
    )
  }

  return (
    <>
      children
    </>
  );
};

export default CustomRoute;
