import LogRocket from 'logrocket';

import { UserDTO } from 'components/types';

const isDevelopmentMode = () => process.env.NODE_ENV === 'development';

const logrocketOff = isDevelopmentMode() || process.env.REACT_APP_API_URL?.includes('stage') ;

export const initLogRocket = () => {
  const LogRocketKey = '8lugdu/gil-admin';

  if (logrocketOff) {
    // console.log('initLogRocket ignored');

    return;
  }

  // Init Session
  LogRocket.init(LogRocketKey, {
    network: {
      requestSanitizer: request => {
        //@ts-ignore
        if (request?.headers?.Authorization) {
          //@ts-ignore
          request.headers.Authorization = '';
        }

        // ignore the request response pair
        //@ts-ignore
        if (request?.url?.toLowerCase().includes('login') || request?.url?.toLowerCase().includes('registration')) {
          return null;
        }

        return request;
      },
    },
  });
};
//@ts-ignore
export const identifyLogRocket = (user: UserDTO)  => {

  if (logrocketOff) {
    // console.log('identifyLogRocket ignored');

    return;
  }

  LogRocket.identify(user.email, {
    name: user?.name,
    email: user.email,
    id: user?.id,
  });
};
