import api from 'api/api';
import { ApiResponse } from 'api/apiTypes';
import { UserDTO, UserRegistrationDTO } from 'components/types';

export const apiGetUser = (): Promise<ApiResponse<UserDTO>> => api.get('/user');
export const apiPatchUser = (data: Partial<UserDTO>): Promise<ApiResponse<UserDTO>> => api.patch('/user', data);

export const apiUserLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<ApiResponse<UserDTO>> =>
  api.post('/login ', { email, password }, { showRequestError: false });

export const apiUserRegistration = (data: UserRegistrationDTO): Promise<ApiResponse<UserDTO>> =>
  api.post('/registration ', data, { showRequestError: false });

export const apiUserInvitationSetPassword = ({
  email,
  password,
  token,
}: {
  email: string;
  password: string;
  token: string;
}): Promise<ApiResponse<UserDTO>> =>
  api.post('/init_user ', { email, password, password_confirmation: password, invitation_token: token }, { showRequestError: false });

export const apiRestoreAccess = ({
  email,
}: {
  email: string;
}): Promise<ApiResponse<UserDTO>> =>
  api.post('/restore_access ', { email }, { showRequestError: false });

export const apiRestoreAccessConfirmation = ({
  email,
  password,
  token,
}: {
  email: string;
  password: string;
  token: string;
}): Promise<ApiResponse<UserDTO>> =>
  api.post('/restore_access_confirmation ', { email, password, password_confirmation: password, token }, { showRequestError: false });

export const apiUserDeleteAccount = () => api.delete('/user');

export const apiAuthByGoogle = (data: any): Promise<ApiResponse<UserDTO>> => api.post('/google_auth_my', data);

export const apiCheckInvitationToken = (data:{invitationToken: string, email: string}): Promise<ApiResponse<UserDTO>> => api.post('/check_invite', data);
