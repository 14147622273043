import React, { FC, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';

import CustomRoute from 'components/CustomRoute/CustomRoute';
import LoginPage from 'components/pages/Auth/LoginPage/LoginPage';
import RegistrationPage from 'components/pages/Auth/RegistrationPage/RegistrationPage';
import CreateNewCompanyPage from 'components/pages/CreateNewCompanyPage/CreateNewCompanyPage';
// import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
// import { REACT_APP_GOOGLE_OAUTH } from 'appInfo';

const InnerPages = React.lazy(() => import('./InnerPages'));

const Routing: FC = () => (
  <>
    {/*<GoogleOAuthProvider clientId={REACT_APP_GOOGLE_OAUTH || ''}>*/}
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/restore_access_confirm" element={<LoginPage mode="restoreAccessConfirm" />} />
      <Route path="/restore_access" element={<LoginPage mode="restoreAccess" />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/invite/registration" element={<LoginPage mode="invitation" />} />
      <Route path="/init_wizard" element={<CreateNewCompanyPage isWizardInitPage />} />
      <Route
        path="/*"
        element={
          <CustomRoute lazy>
            <InnerPages />
          </CustomRoute>
        }
      />
    </Routes>
    {/*</GoogleOAuthProvider>*/}
  </>
);

export default withTranslation()(Routing);
