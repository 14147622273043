import {useEffect} from 'react';
import {useForm, UseFormProps} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {
  useValidationSchemaConstructor
} from 'hooks/useYupForm/yupBaseScheme';

// copied from node_modules/react-hook-form/dist/types.ts
export type FieldValues = Record<string, any>;

function useYupForm<TFieldValues extends FieldValues = FieldValues, TContext = any, TTransformedValues extends FieldValues | undefined = undefined>(props?: UseFormProps<TFieldValues, TContext>) {

  const {i18n} = useTranslation();

  const useFormData = useForm(props);

  useEffect(() => {
    if(Object.keys(useFormData.formState.errors).length) {
      useFormData.trigger();
    }
  }, [i18n.resolvedLanguage]);

  return useFormData;
}

export const useValidationSchema = useValidationSchemaConstructor;

export default useYupForm;
