import React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import cn from 'classnames';

import styles from './InputPassword.module.scss';

export type Props = TextFieldProps & {
  triggerShowPassword: () => void;
  showPassword: boolean;
  registerProps?: any;
}

const InputPassword = ({ triggerShowPassword, showPassword, type, className, registerProps, ...props }: Props) => (
  <TextField
    {...registerProps}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end" className={styles.visibilityIcon}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={triggerShowPassword}
            onMouseDown={triggerShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff/> : <Visibility/>}
          </IconButton>
        </InputAdornment>
      )
    }}
    type={showPassword ? 'text' : 'password'}
    className={cn(styles.component, className)}
    {...props}
  />
)

export default InputPassword;
