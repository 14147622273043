import * as yup from 'yup';

import { OrganizationDTO } from 'types/organization';

export type CompanyAndOrgPOSTDTO = {
  name: string;
  image?: string;
  domain?: string;
  organization: OrganizationDTO
}

export type CompanyDTOBase = {
  id: number;
  name: string;
  image?: string;
  domain?: string;
};
export type CompanyDTO = CompanyDTOBase & {
  organizations: OrganizationDTO[],
};

export const yupSchemeCompanyDTO = {
  name: yup.string().required(),
  image: yup.string(),
  domain: yup.string(),
}

export const yupCompanyDTO = yup
  .object(yupSchemeCompanyDTO)
  .required();
