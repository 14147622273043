import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';

import _ from 'lodash';

type StorageDataType = Record<string, any>;

export type UserStorage = {
  storageData: StorageDataType;
  storageDataRef: StorageDataType;
  setStorageData: (data: StorageDataType) => void;
  setStorageDataByKey: (key: string, data: any) => void;
};

export type LocalStorageProviderContextType = {
  storageData: StorageDataType;
  storageDataRef: StorageDataType;
  setStorageData: (data: StorageDataType) => void;
  setStorageDataByKey: (key: string, data: any) => void;
};

const LocalStorageProviderContextInitialState = {
  storageData: {},
  storageDataRef: {},
  setStorageData: () => {},
  setStorageDataByKey: () => {},
}

const LocalStorageProviderContext = React.createContext<LocalStorageProviderContextType>(LocalStorageProviderContextInitialState);

export const LOCAL_STORAGE_KEY = 'gStorage';
const LocalStorageProvider: FC = ({ children }) => {

  const storageDataRef = useRef(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ||'{}'))
  // const storageData = storageDataRef.current;
  const [storageData, setStorageData ] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ||'{}'))

  const handleSetStorageData = (data: any) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    storageDataRef.current = data;
    setStorageData(data);
  }

  const handleSetStorageDataByKey = useCallback((key: string, data: any) => {
    const newData = _.cloneDeep(storageDataRef.current);

    newData[key] = data;

    handleSetStorageData(newData);
  }, [storageData]);

  const value = useMemo(() => ({
    storageData,
    storageDataRef: storageDataRef.current,
    setStorageData: handleSetStorageData,
    setStorageDataByKey: handleSetStorageDataByKey,
  }), [storageData]);

  return (
    <LocalStorageProviderContext.Provider value={value}>
      {children}
    </LocalStorageProviderContext.Provider>
  );
};

export const useLocalStorage = () => useContext(LocalStorageProviderContext);

export type UserStorageType = {
  storageData: StorageDataType;
  storageDataRef: StorageDataType;
  setStorageData: (data: StorageDataType) => void;
  setStorageDataByKey: (key: string, data: any) => void;
  key: string;
};

export const useLocalStorageByKey = <DEFAULT_VALUE = Record<string, any>,>({key, defaultValue, pathNamePrefix}:{key?: string, defaultValue?: DEFAULT_VALUE, pathNamePrefix?: boolean}) => {
  const mainStorage = useLocalStorage();
  
  const _key = useMemo(() => {
    if(pathNamePrefix) {
      return `${window.location.pathname}-${key}`;
    }

    return key;

  }, [pathNamePrefix]);

  if(!_key) {
    return ({
      storageData: defaultValue as DEFAULT_VALUE,
      setStorageData: () => defaultValue as DEFAULT_VALUE,
      storageDataRef: defaultValue as DEFAULT_VALUE,
      key: 'undefined',
    } as unknown) as UserStorageType;
  }
  
  const storageData = (mainStorage.storageData[_key] || defaultValue) as DEFAULT_VALUE;
  const storageDataRef = (mainStorage.storageDataRef[_key] || defaultValue) as DEFAULT_VALUE;
  const setStorageData = (data: DEFAULT_VALUE) => mainStorage.setStorageDataByKey(_key, data);

  const handleSetStorageDataByKey = useCallback((key: string, data: any) => {
    const newData = (_.cloneDeep(storageDataRef) || {}) as DEFAULT_VALUE;

    if(data) {
      //@ts-ignore
      newData[key] = data;
    } else if (_.isEmpty(data)) {
      //@ts-ignore
      delete newData[key];
    }

    setStorageData(newData)
  }, [storageData]);
    
  const DATA = ({
    storageData,
    storageDataRef,
    setStorageData,
    setStorageDataByKey: handleSetStorageDataByKey,
  } as unknown) as UserStorageType;

  return DATA;
}
export default LocalStorageProvider;
